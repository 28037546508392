import formatters from '../mixins/formattersLib'

function getNumeroOrdinal(n) {
   if (n === 1) return 'ra'
   if (n === 2) return 'da'
   if (n === 3) return 'ra'
   if (n === 4) return 'ta'
}
export function dictamenAdminRenovacion(info) {
   let fechaActualSplit = info.fechaActual.split('/')
   return [
      {
         style: 'headerMain',
         alignment: 'center',
         text: [
            'UNIVERSIDAD CATÓLICA DE EL SALVADOR\n',
            '\nDICTAMEN FINAL DEL CONSEJO ADMINISTRATIVO',
            '\nSOBRE EL OTORGAMIENTO DE BECA',
            '\nCICLO ACADÉMICO ' + info.cicloDeAplicacion
         ]
      },
      {
         style: 'infoAlumno',
         text: [
            'CARNET: ',
            { text: info.carnet + '\n', style: 'texto', bold: false }
         ]
      },
      {
         style: 'infoAlumno',
         text: [
            'ALUMNO: ',
            {
               text: info.nombres + ' ' + info.apellidos + '\n',
               style: 'texto',
               bold: false
            }
         ]
      },
      {
         style: 'infoAlumno',
         text: [
            'CARRERA: ',
            {
               text: info.carreraUniversitaria + '\n',
               style: 'texto',
               bold: false
            }
         ]
      },
      {
         style: 'texto',
         text: [
            'El Consejo Administrativo de la Universidad Católica de El Salvador, después de analizar el ',
            'rendimiento académico obtenido en el ciclo ' +
               info.cicloAnterior +
               ', habiendo aprobado todas las asignaturas en la ',
            'malla curricular y cumplir con los requisitos de becarios establecidos en el Art. 4, Art. 8 y Art. 2 ',
            'del Instructivo de Becas para Empleados, Hijos de Empleados, Sacerdotes y Miembros de',
            'Congregaciones Religiosas.'
         ]
      },
      {
         text: 'ACUERDA:',
         style: 'subheader1'
      },
      {
         style: 'texto',
         text: [
            { text: 'RENOVAR, ', style: 'subheader1' },
            'para el ciclo académico ' +
               info.cicloDeAplicacion +
               ', desde la ' +
               info.cuotaInicial +
               getNumeroOrdinal(info.cuotaInicial) +
               ' cuota, la ' +
               info.tipoBeca +
               ' a la alumno/a ',
            info.nombres +
               ' ' +
               info.apellidos +
               ', para el Proceso de Graduación, el cual incluye:',
            '\n\n- Seminario de graduación',
            '\n- Inscripción de trabajo de graduación',
            '\n- Evaluación de trabajo de graduación',
            '\n- Asesor',
            '\n\nEl alumno/a ' +
               info.nombres +
               ' ' +
               info.apellidos +
               ', deberá cancelar los demás aranceles',
            ' que no cubre la beca.'
         ]
      },
      {
         style: 'subheader1',
         text: [
            'NOTIFÍQUESE',
            {
               text: `\nSanta Ana, a los ${formatters.methods
                  .numeroLetras(fechaActualSplit[0])
                  .toLowerCase()} días del mes de ${formatters.methods.obtenerMesFromNumber(
                  fechaActualSplit[1]
               )} de ${fechaActualSplit[2]}.\n`,
               style: 'texto',
               bold: false
            }
         ]
      },
      {
         text: '"LA CIENCIA SIN MORAL ES VANA"',
         style: 'header',
         alignment: 'center'
      },
      {
         style: 'tableExample',
         table: {
            widths: [160],
            body: [
               [
                  {
                     border: [false, true, false, false],
                     text: 'Licda. Michelle Espinoza \nDirectora Administrativa',
                     alignment: 'center'
                  }
               ]
            ]
         }
      },
      {
         style: 'footer1',
         pageBreak: 'after',
         text: [
            'CC.: Alumno(a)',
            '\nDirectora de Registro Académico (para archivo expediente del alumno)',
            '\nDirección Administrativa (para su archivo y control)',
            '\nUnidad de Servicios Estudiantiles e Internacionalización (para archivo expediente del alumno)',
            '\nAdmin-Ren'
         ]
      }
   ]
}

export function dictamenServicioEstudiantilrenovacion(info) {
   let fechaActualSplit = info.fechaActual.split('/')
   return [
      {
         style: 'headerMain',
         alignment: 'center',
         text: [
            'UNIVERSIDAD CATÓLICA DE EL SALVADOR\n',
            '\nDICTAMEN FINAL DEL CONSEJO ADMINISTRATIVO',
            '\nSOBRE EL OTORGAMIENTO DE BECA',
            '\nCICLO ACADÉMICO ' + info.cicloDeAplicacion
         ]
      },
      {
         style: 'infoAlum',
         text: [
            'CARNET: ',
            { text: info.carnet + '\n', style: 'texto', bold: false }
         ]
      },
      {
         style: 'infoAlum',
         text: [
            'ALUMNO: ',
            {
               text: info.nombres + ' ' + info.apellidos + '\n',
               style: 'texto',
               bold: false
            }
         ]
      },
      {
         style: 'infoAlum',
         text: [
            'CARRERA: ',
            {
               text: info.carreraUniversitaria + '\n',
               style: 'texto',
               bold: false
            }
         ]
      },
      {
         style: 'texto',
         text: [
            'El Consejo Administrativo de la Universidad Católica de El Salvador, después de analizar ',
            'el rendimiento académico obtenido en el ciclo ' +
               info.cicloAnterior +
               ' y de haber cumplido con las ',
            'obligaciones de becarios como lo establece el Art. 10 del Reglamento de Becas.'
         ]
      },
      {
         text: 'ACUERDA:',
         style: 'subheader'
      },
      {
         style: 'texto',
         text: [
            { text: 'RENOVAR, ', style: 'subheader' },
            'para el ciclo académico ' +
               info.cicloDeAplicacion +
               ', desde la ' +
               info.cuotaInicial +
               getNumeroOrdinal(info.cuotaInicial) +
               '. cuota, la ' +
               info.tipoBeca +
               ' que le',
            ' fue autorizada en el ciclo ' +
               info.cicloAnterior +
               ' al alumno ' +
               info.nombres +
               info.apellidos +
               '.'
         ]
      },
      {
         style: 'subheader',
         text: [
            'NOTIFÍQUESE',
            {
               text: `\nSanta Ana, a los ${formatters.methods
                  .numeroLetras(fechaActualSplit[0])
                  .toLowerCase()} días del mes de ${formatters.methods.obtenerMesFromNumber(
                  fechaActualSplit[1]
               )} de ${fechaActualSplit[2]}.\n`,
               style: 'texto',
               bold: false
            }
         ]
      },
      {
         text: '"LA CIENCIA SIN MORAL ES VANA"',
         style: 'header',
         alignment: 'center'
      },
      {
         style: 'tableExample',
         table: {
            widths: [160],
            body: [
               [
                  {
                     border: [false, true, false, false],
                     text: 'Licda. Michelle Espinoza \nDirectora Administrativa',
                     alignment: 'center'
                  }
               ]
            ]
         }
      },
      {
         style: 'footer',
         pageBreak: 'after',
         text: [
            'CC.: Alumno(a)',
            '\nDirectora de Registro Académico (para archivo expediente del alumno)',
            '\nDirección Administrativa (para su archivo y control)',
            '\nUnidad de Servicios Estudiantiles e Internacionalización (para archivo expediente del alumno)',
            '\nServEst-Ren'
         ]
      }
   ]
}

export function dictamenAdminNuevaPostgrado(info) {
   let fechaActualSplit = info.fechaActual.split('/')
   return [
      {
         style: 'headerMain',
         alignment: 'center',
         text: [
            'UNIVERSIDAD CATÓLICA DE EL SALVADOR\n',
            '\nDICTAMEN FINAL DEL CONSEJO ADMINISTRATIVO',
            '\nSOBRE EL OTORGAMIENTO DE BECA',
            '\nCICLO ACADÉMICO ' + info.cicloDeAplicacion
         ]
      },
      {
         style: 'infoAlum',
         text: [
            'CARNET: ',
            { text: info.carnet + '\n', style: 'texto', bold: false }
         ]
      },
      {
         style: 'infoAlum',
         text: [
            'ALUMNO: ',
            {
               text: info.nombres + ' ' + info.apellidos + '\n',
               style: 'texto',
               bold: false
            }
         ]
      },
      {
         style: 'infoAlum',
         text: [
            'CARRERA: ',
            {
               text: info.carreraUniversitaria + '\n',
               style: 'texto',
               bold: false
            }
         ]
      },
      {
         style: 'texto',
         text: [
            'El Consejo Administrativo de la Universidad Católica de El Salvador, después de analizar',
            'el rendimiento académico obtenido en el ciclo ' +
               info.cicloAnterior +
               ' y de haber cumplido con los',
            'requisitos de becarios como lo establece el Art. 4 y Art. 8 del Instructivo de Becas para ',
            'Empleados, Hijos de Empleados, Sacerdotes y Miembros de Congregaciones Religiosas.\n'
         ]
      },
      {
         text: 'ACUERDA:',
         style: 'subheader3'
      },
      {
         style: 'subheader3',
         text: [
            'RENOVAR, ',
            {
               text:
                  'para el ciclo académico ' +
                  info.cicloDeAplicacion +
                  ', desde la ' +
                  info.cuotaInicial +
                  getNumeroOrdinal(info.cuotaInicial) +
                  '. cuota, la ' +
                  info.tipoBeca +
                  ' que le fue autorizada en el ciclo ' +
                  info.cicloAnterior +
                  ' ' +
                  info.nombres +
                  ' ' +
                  info.apellidos +
                  '.\n',
               style: 'texto',
               bold: false
            }
         ]
      },
      {
         style: 'subheader3',
         text: [
            'NOTIFÍQUESE',
            {
               text: `\nSanta Ana, a los ${formatters.methods
                  .numeroLetras(fechaActualSplit[0])
                  .toLowerCase()} días del mes de ${formatters.methods.obtenerMesFromNumber(
                  fechaActualSplit[1]
               )} de ${fechaActualSplit[2]}.\n`,
               style: 'texto',
               bold: false
            }
         ]
      },
      {
         text: '"LA CIENCIA SIN MORAL ES VANA"',
         style: 'header',
         alignment: 'center'
      },
      {
         style: 'tableExample',
         table: {
            widths: [160],
            body: [
               [
                  {
                     border: [false, true, false, false],
                     text: 'Licda. Michelle Espinoza \nDirectora Administrativa',
                     alignment: 'center'
                  }
               ]
            ]
         }
      },
      {
         style: 'footer',
         pageBreak: 'after',
         text: [
            'CC.: Alumno(a)',
            '\nDirectora de Registro Académico (para archivo expediente del alumno)',
            '\nDirección Administrativa (para su archivo y control)',
            '\nUnidad de Servicios Estudiantiles e Internacionalización (para archivo expediente del alumno)',
            '\nAdmin-Ren'
         ]
      }
   ]
}

export function dictamenAdminNueva(info) {
   let fechaActualSplit = info.fechaActual.split('/')
   return [
      {
         style: 'headerMain',
         alignment: 'center',
         text: [
            'UNIVERSIDAD CATÓLICA DE EL SALVADOR\n',
            '\nDICTAMEN FINAL DEL CONSEJO ADMINISTRATIVO',
            '\nSOBRE EL OTORGAMIENTO DE BECA',
            '\nCICLO ACADÉMICO ' + info.cicloDeAplicacion
         ]
      },
      {
         style: 'texto',
         text: [
            'El Consejo Administrativo de la Universidad Católica de El Salvador, UNICAES, después de ',
            'analizar la solicitud de beca efectuada por ' +
               info.nombres +
               ' ' +
               info.apellidos +
               ', con carnet',
            ' No. ' +
               info.carnet +
               ', estudiante de la carrera de ' +
               info.carreraUniversitaria +
               '.',
            '\n\nSobre la base de lo anterior, el Consejo Administrativo,\n\n'
         ]
      },
      {
         text: 'ACUERDA:',
         style: 'subheader'
      },
      {
         style: 'texto',
         text: [
            { text: 'OTORGAR, ', style: 'subheader' },
            'para el ciclo académico ' +
               info.cicloDeAplicacion +
               ', desde la ' +
               info.cuotaInicial +
               getNumeroOrdinal(info.cuotaInicial) +
               '. cuota la ' +
               info.tipoBeca +
               ' al estudiante ',
            info.nombres +
               ' ' +
               info.apellidos +
               ', pero deberá cumplir con las obligaciones de becarios',
            'según el Art. 4 y Art. 8 del Instructivo de Becas para Empleados, Hijos de Empleados, Sacerdotes ',
            'y Miembros de Congregaciones Religiosas'
         ]
      },
      {
         style: 'subheader',
         text: [
            'NOTIFÍQUESE',
            {
               text: `\nSanta Ana, a los ${formatters.methods
                  .numeroLetras(fechaActualSplit[0])
                  .toLowerCase()} días del mes de ${formatters.methods.obtenerMesFromNumber(
                  fechaActualSplit[1]
               )} de ${fechaActualSplit[2]}.\n`,
               style: 'texto',
               bold: false
            }
         ]
      },
      {
         text: '"LA CIENCIA SIN MORAL ES VANA"',
         style: 'header',
         alignment: 'center'
      },
      {
         columns: [
            {
               text: [
                  {
                     text: 'PhD. MOISÉS ANTONIO MARTÍNEZ ZALDÍVAR',
                     fontSize: 10,
                     alignment: 'center'
                  },
                  {
                     text: '\nVicerrector',
                     fontSize: 12,
                     bold: true,
                     alignment: 'center'
                  }
               ]
            },
            {
               text: [
                  {
                     text: 'MSC. MOISÉS ULISES GARCÍA PERDIDO',
                     fontSize: 10,
                     alignment: 'center'
                  },
                  {
                     text: '\nSecretario General',
                     fontSize: 12,
                     bold: true,
                     alignment: 'center'
                  }
               ]
            }
         ]
      },
      {
         style: 'espaciado',
         columns: [
            {
               text: [
                  {
                     text: 'MSC. ROBERTO ANTONIO LÓPEZ CASTRO',
                     fontSize: 10,
                     alignment: 'center'
                  },
                  {
                     text: '\nVicerrector Académico',
                     fontSize: 12,
                     bold: true,
                     alignment: 'center'
                  }
               ]
            },
            {
               text: [
                  {
                     text: 'MTRA. ANA MICHELLE ESPINOZA',
                     fontSize: 10,
                     alignment: 'center'
                  },
                  {
                     text: '\nDirectora Administrativa',
                     fontSize: 12,
                     bold: true,
                     alignment: 'center'
                  }
               ]
            }
         ]
      },
      {
         style: 'footer',
         pageBreak: 'after',
         text: [
            'CC.: Alumno(a)',
            '\nRegistro Académico (para archivo expediente del alumno)',
            '\nDirección Administrativa (para su archivo y control)',
            '\nDireccion de Servicios Estudiantiles e Internacionalización (para archivo expediente del alumno)',
            '\nAdmin-Nuevo'
         ]
      }
   ]
}

export function dictamenServicioEstudiantilDenegada(info) {
   let fechaActualSplit = info.fechaActual.split('/')
   return [
      {
         style: 'headerMain',
         alignment: 'center',
         text: [
            'UNIVERSIDAD CATÓLICA DE EL SALVADOR\n',
            '\nDICTAMEN FINAL DEL CONSEJO ADMINISTRATIVO',
            '\nSOBRE EL OTORGAMIENTO DE BECA',
            '\nCICLO ACADÉMICO ' + info.cicloDeAplicacion
         ]
      },
      {
         style: 'texto',
         text: [
            'El Consejo Administrativo de la Universidad Católica de El Salvador, UNICAES, después de ',
            'analizar la solicitud de beca efectuada por ' +
               info.nombres +
               ' ' +
               info.apellidos +
               ', con carnet',
            ' No. ' +
               info.carnet +
               ', estudiante de la carrera de ' +
               info.carreraUniversitaria +
               '.',
            '\n\nSobre la base de lo anterior, el Consejo Administrativo,\n\n'
         ]
      },
      {
         text: 'ACUERDA:',
         style: 'subheader3'
      },
      {
         style: 'subheader3',
         text: [
            'DENEGAR, ',
            {
               text:
                  'para el ciclo académico ' +
                  info.cicloDeAplicacion +
                  ', BECA al alumno/a ' +
                  info.nombres +
                  ' ' +
                  info.apellidos +
                  '.\n',
               style: 'texto',
               bold: false
            }
         ]
      },
      {
         style: 'subheader3',
         text: [
            'NOTIFÍQUESE',
            {
               text: `\nSanta Ana, a los ${formatters.methods
                  .numeroLetras(fechaActualSplit[0])
                  .toLowerCase()} días del mes de ${formatters.methods.obtenerMesFromNumber(
                  fechaActualSplit[1]
               )} de ${fechaActualSplit[2]}.\n`,
               style: 'texto',
               bold: false
            }
         ]
      },
      {
         text: '"LA CIENCIA SIN MORAL ES VANA"',
         style: 'header',
         alignment: 'center'
      },
      {
         columns: [
            {
               text: [
                  {
                     text: 'PhD. MOISÉS ANTONIO MARTÍNEZ ZALDÍVAR',
                     fontSize: 10,
                     alignment: 'center'
                  },
                  {
                     text: '\nVicerrector',
                     fontSize: 12,
                     bold: true,
                     alignment: 'center'
                  }
               ]
            },
            {
               text: [
                  {
                     text: 'MSC. MOISÉS ULISES GARCÍA PERDIDO',
                     fontSize: 10,
                     alignment: 'center'
                  },
                  {
                     text: '\nSecretario General',
                     fontSize: 12,
                     bold: true,
                     alignment: 'center'
                  }
               ]
            }
         ]
      },
      {
         style: 'espaciado',
         columns: [
            {
               text: [
                  {
                     text: 'MSC. ROBERTO ANTONIO LÓPEZ CASTRO',
                     fontSize: 10,
                     alignment: 'center'
                  },
                  {
                     text: '\nVicerrector Académico',
                     fontSize: 12,
                     bold: true,
                     alignment: 'center'
                  }
               ]
            },
            {
               text: [
                  {
                     text: 'MTRA. ANA MICHELLE ESPINOZA',
                     fontSize: 10,
                     alignment: 'center'
                  },
                  {
                     text: '\nDirectora Administrativa',
                     fontSize: 12,
                     bold: true,
                     alignment: 'center'
                  }
               ]
            }
         ]
      },
      {
         style: 'footer',
         pageBreak: 'after',
         text: [
            'CC.: Alumno(a)',
            '\nRegistro Académico (para archivo expediente del alumno)',
            '\nDirección Administrativa (para su archivo y control)',
            '\nDireccion de Servicios Estudiantiles e Internacionalización (para archivo expediente del alumno)',
            '\nServEst-Nue'
         ]
      }
   ]
}

export function dictamenServicioEstudiantilNueva(info) {
   let fechaActualSplit = info.fechaActual.split('/')
   return [
      {
         style: 'headerMain',
         alignment: 'center',
         text: [
            'UNIVERSIDAD CATÓLICA DE EL SALVADOR\n',
            '\nDICTAMEN FINAL DEL CONSEJO ADMINISTRATIVO',
            '\nSOBRE EL OTORGAMIENTO DE BECA',
            '\nCICLO ACADÉMICO ' + info.cicloDeAplicacion
         ]
      },
      {
         style: 'texto',
         text: [
            'El Consejo Administrativo de la Universidad Católica de El Salvador, UNICAES, después de ',
            'analizar la solicitud de beca efectuada por ' +
               info.nombres +
               ' ' +
               info.apellidos +
               ', con carnet',
            ' No. ' +
               info.carnet +
               ', estudiante de la carrera de ' +
               info.carreraUniversitaria +
               '.',
            '\n\nSobre la base de lo anterior, el Consejo Administrativo,\n\n\n'
         ]
      },
      {
         text: 'ACUERDA:',
         style: 'subheader'
      },
      {
         style: 'texto',
         text: [
            { text: 'OTORGAR, ', style: 'subheader' },
            'para el ciclo académico ' +
               info.cicloDeAplicacion +
               ', desde la ' +
               info.cuotaInicial +
               getNumeroOrdinal(info.cuotaInicial) +
               '. cuota la ' +
               info.tipoBeca +
               ' a la alumna ',
            info.nombres +
               ' ' +
               info.apellidos +
               ', pero deberá cumplir con las obligaciones de becarios según el Art. 10 del Reglamento de Becas.'
         ]
      },
      {
         style: 'subheader',
         text: [
            'NOTIFÍQUESE',
            {
               text: `\nSanta Ana, a los ${formatters.methods
                  .numeroLetras(fechaActualSplit[0])
                  .toLowerCase()} días del mes de ${formatters.methods.obtenerMesFromNumber(
                  fechaActualSplit[1]
               )} de ${fechaActualSplit[2]}.\n`,
               style: 'texto',
               bold: false
            }
         ]
      },
      {
         text: '"LA CIENCIA SIN MORAL ES VANA"',
         style: 'header',
         alignment: 'center'
      },
      {
         columns: [
            {
               text: [
                  {
                     text: 'PhD. MOISÉS ANTONIO MARTÍNEZ ZALDÍVAR',
                     fontSize: 10,
                     alignment: 'center'
                  },
                  {
                     text: '\nVicerrector',
                     fontSize: 12,
                     bold: true,
                     alignment: 'center'
                  }
               ]
            },
            {
               text: [
                  {
                     text: 'MSC. MOISÉS ULISES GARCÍA PERDIDO',
                     fontSize: 10,
                     alignment: 'center'
                  },
                  {
                     text: '\nSecretario General',
                     fontSize: 12,
                     bold: true,
                     alignment: 'center'
                  }
               ]
            }
         ]
      },
      {
         style: 'espaciado',
         columns: [
            {
               text: [
                  {
                     text: 'MSC. ROBERTO ANTONIO LÓPEZ CASTRO',
                     fontSize: 10,
                     alignment: 'center'
                  },
                  {
                     text: '\nVicerrector Académico',
                     fontSize: 12,
                     bold: true,
                     alignment: 'center'
                  }
               ]
            },
            {
               text: [
                  {
                     text: 'MTRA. ANA MICHELLE ESPINOZA',
                     fontSize: 10,
                     alignment: 'center'
                  },
                  {
                     text: '\nDirectora Administrativa',
                     fontSize: 12,
                     bold: true,
                     alignment: 'center'
                  }
               ]
            }
         ]
      },
      {
         style: 'footer',
         pageBreak: 'after',
         text: [
            'CC.: Alumno(a)',
            '\nRegistro Académico (para archivo expediente del alumno)',
            '\nDirección Administrativa (para su archivo y control)',
            '\nDireccion de Servicios Estudiantiles e Internacionalización (para archivo expediente del alumno)',
            '\nServEst-Nue'
         ]
      }
   ]
}
