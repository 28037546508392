<template>
   <v-layout align-start>
      <v-flex>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <!-- DIALOG DE REAPLICACION -->
         <v-dialog v-model="dialogReaplicacion" persistent max-width="450">
            <v-form>
               <v-card>
                  <v-card-text
                     class="text--primary mb-0 pb-0"
                     style="margin-top: -20px"
                  >
                     <v-container grid-list-md fluid class="mb-0 pb-0">
                        <v-layout wrap>
                           <v-flex xs12 mt-4>
                              <span class="text-h5 font-weight-medium">
                                 Reaplicar solicitudes <br />
                                 <span class="colorPrimary">
                                    Ciclo {{ selectCiclo }}
                                    <v-icon class="mx-2" color="primary">
                                       fa-long-arrow-alt-right
                                    </v-icon>
                                    {{ cicloActual }}
                                 </span>
                              </span>
                           </v-flex>
                           <v-flex xs12 sm4 class="d-flex align-center">
                              <v-switch
                                 v-model="swReaplicarCarnet"
                                 label="Individual"
                              ></v-switch>
                           </v-flex>
                           <v-flex xs12 sm8 class="d-flex align-center">
                              <v-text-field
                                 v-model="reaplicacionCarnet"
                                 v-mask="'####-AA-###'"
                                 outlined
                                 dense
                                 label="Carnet"
                                 hide-details
                                 :disabled="swReaplicarCarnet == false"
                                 color="primary"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 mb-2>
                              <v-btn
                                 :disabled="
                                    selectCiclo == cicloActual ||
                                    loadingReaplicacion
                                 "
                                 :loading="loadingReaplicacion"
                                 color="primary"
                                 block
                                 @click="
                                    swReaplicarCarnet
                                       ? reaplicarCarnet()
                                       : reaplicarCiclo()
                                 "
                              >
                                 {{
                                    swReaplicarCarnet
                                       ? 'Reaplicar carnet'
                                       : 'Reaplicar ciclo'
                                 }}
                                 <template #loader>
                                    <span>Procesando, por favor espere...</span>
                                 </template>
                              </v-btn>
                           </v-flex>
                           <v-flex xs12>
                              <v-textarea
                                 v-model="reaplicacionOutput"
                                 :loading="loadingReaplicacion"
                                 filled
                                 readonly
                                 label="Solicitudes reaplicadas"
                              ></v-textarea>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="margin-right: 20px; padding-bottom: 20px"
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        :disabled="loadingReaplicacion"
                        color="primary"
                        text
                        @click.native="closeDialogReaplicacion"
                        >Salir</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG REPORTES -->
         <v-dialog v-model="dialogRepGrupales" persistent max-width="450">
            <v-form>
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md fluid>
                        <v-layout wrap>
                           <v-flex xs12 mt-4>
                              <span class="text-h5 font-weight-medium">
                                 Reportes de solicitudes <br />
                                 <span class="colorPrimary">
                                    Ciclo {{ selectCiclo }}
                                 </span>
                              </span>
                           </v-flex>
                           <v-flex xs12 mt-2>
                              <v-btn
                                 color="green darken-3 white--text"
                                 block
                                 @click="reporteExcelSolicitudes"
                              >
                                 <v-icon left>fa-file-excel</v-icon>
                                 Reporte excel
                              </v-btn>
                           </v-flex>
                           <v-flex xs12 mt-4>
                              <v-expansion-panels>
                                 <v-expansion-panel>
                                    <v-expansion-panel-header>
                                       <div>
                                          <v-icon left>fas fa-sliders-h</v-icon>
                                          Opciones resumen general
                                       </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                       <v-layout wrap>
                                          <v-flex xs12>
                                             <span
                                                class="text-subtitle-1 font-weight-medium"
                                                >Filtrar por</span
                                             >
                                          </v-flex>
                                          <v-flex xs12>
                                             <div class="layout justify-center">
                                                <v-radio-group
                                                   v-model="radioResumenGeneral"
                                                   class="justify-center"
                                                   row
                                                >
                                                   <v-radio
                                                      color="primary"
                                                      label="Sin filtrar"
                                                      value="r-nofiltrar"
                                                   ></v-radio>
                                                   <v-radio
                                                      color="primary"
                                                      label="Carrera"
                                                      value="r-carrera"
                                                   ></v-radio>
                                                   <v-radio
                                                      color="primary"
                                                      label="Facultad"
                                                      value="r-facultad"
                                                   ></v-radio>
                                                </v-radio-group>
                                             </div>
                                          </v-flex>
                                          <v-flex
                                             v-if="
                                                radioResumenGeneral ==
                                                'r-carrera'
                                             "
                                             xs12
                                          >
                                             <v-autocomplete
                                                v-model="selectCarrera"
                                                :items="cmbCarrera"
                                                label="Carrera"
                                                no-data-text="Sin resultados"
                                                item-text="nombre"
                                                item-value="carreraUniversitariaId"
                                             ></v-autocomplete>
                                          </v-flex>
                                          <v-flex
                                             v-if="
                                                radioResumenGeneral ==
                                                'r-facultad'
                                             "
                                             xs12
                                          >
                                             <v-autocomplete
                                                v-model="selectFacultad"
                                                :items="cmbFacultad"
                                                label="Facultad"
                                                no-data-text="Sin resultados"
                                                item-text="nombre"
                                                item-value="unidadAcademicaId"
                                             ></v-autocomplete>
                                          </v-flex>
                                       </v-layout>
                                    </v-expansion-panel-content>
                                 </v-expansion-panel>
                              </v-expansion-panels>
                           </v-flex>
                           <v-flex xs12 mt-2>
                              <v-btn
                                 color="green darken-3 white--text"
                                 block
                                 @click="
                                    reporteResumenGeneral(
                                       selectCarrera,
                                       selectFacultad
                                    )
                                 "
                              >
                                 <v-icon left>fa-file-excel</v-icon>
                                 Resumen general
                              </v-btn>
                           </v-flex>
                           <v-flex xs12 mt-2>
                              <v-btn
                                 color="primary white--text"
                                 block
                                 @click="generarCartasBecarios()"
                              >
                                 <v-icon left color="white"
                                    >fa-solid fa-envelope</v-icon
                                 >
                                 Generar Cartas
                              </v-btn>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -20px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        text
                        @click.native="dialogRepGrupales = false"
                        >Salir</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>
         <v-dialog v-model="dialogReporteLoading" persistent width="300">
            <v-card color="primary" dark class="pt-5 pb-2">
               <v-card-text style="color: white">
                  Procesando...
                  <v-progress-linear
                     indeterminate
                     color="white"
                     class="mb-0"
                  ></v-progress-linear>
               </v-card-text>
            </v-card>
         </v-dialog>
         <!-- DIALOG REPORTES INDIVUDALES DE SOLICITUD -->
         <ReportesSoliBeca
            v-model="dialogRepIndividuales"
            :soli-beca-id="numeroCaso"
            :numero-ref="numeroRef"
            :carrera-id="carreraId"
         />

         <!-- CONTAINER VER SOLICITUD -->
         <v-container v-if="verNuevo" :fluid="$vuetify.breakpoint.mdAndDown">
            <v-layout wrap>
               <v-flex xs12 text-center mb-4>
                  <v-btn
                     rounded
                     outlined
                     largec
                     class="mr-1"
                     color="primary"
                     @click="regresar"
                     >Regresar</v-btn
                  >
                  <v-btn
                     v-if="checkModificarAsesor()"
                     rounded
                     outlined
                     large
                     class="ml-1"
                     color="primary"
                     @click="dialogRepIndividuales = true"
                     >Reportes individuales</v-btn
                  >
               </v-flex>
               <v-flex class="font-weight-medium text-h6" xs12 text-center mb-4>
                  Su número de referencia es: <br />
                  <span class="colorPrimary font-weight-bold">
                     {{ solicitudBecaRef }}
                  </span>
               </v-flex>
            </v-layout>

            <v-stepper
               v-model="stepNum"
               non-linear
               :class="$vuetify.breakpoint.xsOnly ? 'elevation-0' : ''"
            >
               <v-stepper-header
                  v-if="solicitudBeneId != null"
                  style="overflow: hidden"
               >
                  <v-stepper-step editable step="3">
                     Datos generales
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                     v-if="
                        solicitudBeneId == 1 ||
                        solicitudBeneId == 2 ||
                        solicitudBeneId == 4 ||
                        solicitudBeneId == 8 ||
                        solicitudBeneId == 9
                     "
                     editable
                     step="3.1"
                  >
                     Datos laborales
                  </v-stepper-step>

                  <v-divider
                     v-if="
                        solicitudBeneId == 1 ||
                        solicitudBeneId == 2 ||
                        solicitudBeneId == 4 ||
                        solicitudBeneId == 8 ||
                        solicitudBeneId == 9
                     "
                  ></v-divider>

                  <v-stepper-step
                     v-if="
                        solicitudBeneId == 1 ||
                        solicitudBeneId == 2 ||
                        solicitudBeneId == 4 ||
                        solicitudBeneId == 8 ||
                        solicitudBeneId == 9
                     "
                     editable
                     step="3.2"
                  >
                     Datos familiares
                  </v-stepper-step>

                  <v-divider
                     v-if="
                        solicitudBeneId == 1 ||
                        solicitudBeneId == 2 ||
                        solicitudBeneId == 4 ||
                        solicitudBeneId == 8 ||
                        solicitudBeneId == 9
                     "
                  ></v-divider>

                  <v-stepper-step
                     v-if="
                        solicitudBeneId == 1 ||
                        solicitudBeneId == 2 ||
                        solicitudBeneId == 4 ||
                        solicitudBeneId == 8 ||
                        solicitudBeneId == 9
                     "
                     editable
                     step="3.3"
                  >
                     Bienes familiares
                  </v-stepper-step>

                  <v-divider
                     v-if="
                        solicitudBeneId == 1 ||
                        solicitudBeneId == 2 ||
                        solicitudBeneId == 4 ||
                        solicitudBeneId == 8 ||
                        solicitudBeneId == 9
                     "
                  ></v-divider>

                  <v-stepper-step
                     v-if="
                        solicitudBeneId == 1 ||
                        solicitudBeneId == 2 ||
                        solicitudBeneId == 4 ||
                        solicitudBeneId == 8 ||
                        solicitudBeneId == 9
                     "
                     editable
                     step="3.4"
                  >
                     Ingresos y egresos
                  </v-stepper-step>

                  <v-divider
                     v-if="
                        solicitudBeneId == 1 ||
                        solicitudBeneId == 2 ||
                        solicitudBeneId == 4 ||
                        solicitudBeneId == 8 ||
                        solicitudBeneId == 9
                     "
                  ></v-divider>

                  <v-stepper-step
                     v-if="
                        solicitudBeneId == 1 ||
                        solicitudBeneId == 2 ||
                        solicitudBeneId == 8 ||
                        solicitudBeneId == 9
                     "
                     editable
                     step="3.5"
                  >
                     Ensayo
                  </v-stepper-step>

                  <v-divider
                     v-if="
                        solicitudBeneId == 1 ||
                        solicitudBeneId == 2 ||
                        solicitudBeneId == 8 ||
                        solicitudBeneId == 9
                     "
                  ></v-divider>

                  <v-stepper-step editable step="4">
                     Comprobantes
                  </v-stepper-step>
               </v-stepper-header>

               <v-stepper-items
                  :class="$vuetify.breakpoint.smAndUp ? 'px-8' : 'py-2'"
               >
                  <!-- PASO 3 -->
                  <v-stepper-content
                     step="3"
                     :style="
                        $vuetify.breakpoint.xsOnly
                           ? 'margin: 0px 0px; padding: 0px 0px;'
                           : ''
                     "
                  >
                     <FormBecasDatosGenerales
                        :solicitud-info-prop="solicitudInfo"
                        @nextStep="nextStepDatosGenerales($event)"
                     />
                  </v-stepper-content>

                  <!-- PASO 3.1 -->
                  <v-stepper-content
                     step="3.1"
                     :style="
                        $vuetify.breakpoint.xsOnly
                           ? 'margin: 0px 0px; padding: 0px 0px;'
                           : ''
                     "
                  >
                     <FormBecasLaboral
                        :solicitud-beca-id="solicitudBecaId"
                        :completada="completada"
                        @cancelGoStep="stepNum = $event"
                        @nextStep="stepNum = $event"
                     />
                  </v-stepper-content>

                  <!-- PASO 3.2 -->
                  <v-stepper-content
                     step="3.2"
                     :style="
                        $vuetify.breakpoint.xsOnly
                           ? 'margin: 0px 0px; padding: 0px 0px;'
                           : ''
                     "
                  >
                     <FormBecasNucleoFamiliar
                        :solicitud-beca-id="solicitudBecaId"
                        :completada="completada"
                        @cancelGoStep="stepNum = $event"
                        @nextStep="stepNum = $event"
                     />
                  </v-stepper-content>

                  <!-- PASO 3.3 -->
                  <v-stepper-content
                     step="3.3"
                     :style="
                        $vuetify.breakpoint.xsOnly
                           ? 'margin: 0px 0px; padding: 0px 0px;'
                           : ''
                     "
                  >
                     <FormBecasBienesFamiliares
                        :solicitud-beca-id="solicitudBecaId"
                        :completada="completada"
                        @cancelGoStep="stepNum = $event"
                        @nextStep="stepNum = $event"
                     />
                  </v-stepper-content>

                  <!-- PASO 3.4 -->
                  <v-stepper-content
                     step="3.4"
                     :style="
                        $vuetify.breakpoint.xsOnly
                           ? 'margin: 0px 0px; padding: 0px 0px;'
                           : ''
                     "
                  >
                     <FormBecasContIngresosEgresos
                        :solicitud-beca-id="solicitudBecaId"
                        :completada="completada"
                        @cancelGoStep="stepNum = $event"
                        @nextStep="stepNum = $event"
                     />
                  </v-stepper-content>

                  <!-- PASO 3.5 -->
                  <v-stepper-content
                     step="3.5"
                     :style="
                        $vuetify.breakpoint.xsOnly
                           ? 'margin: 0px 0px; padding: 0px 0px;'
                           : ''
                     "
                  >
                     <FormBecasEnsayo
                        :solicitud-beca-id="solicitudBecaId"
                        :completada="completada"
                        @cancelGoStep="stepNum = $event"
                        @nextStep="stepNum = $event"
                     />
                  </v-stepper-content>

                  <!-- PASO 4 -->
                  <v-stepper-content
                     step="4"
                     :style="
                        $vuetify.breakpoint.xsOnly
                           ? 'margin: 0px 0px; padding: 0px 0px;'
                           : ''
                     "
                  >
                     <FormBecasComprobantes
                        :solicitud-beca-id="solicitudBecaId"
                        :beneficiario-id="selectBeneficiario"
                        :listar="stepNum == 4"
                        :completada="completada"
                        @cancelGoStep="stepNum = $event"
                     />
                  </v-stepper-content>
               </v-stepper-items>
            </v-stepper>
         </v-container>

         <!-- LISTA DE SOLICITUDES -->
         <v-card v-if="!verNuevo" elevation="0" outlined max-width="5000">
            <v-toolbar color="primary" dark>
               <v-toolbar-title style="font-weight: 500">
                  Solicitudes de becas
               </v-toolbar-title>
               <div class="mt-6 ml-3" style="width: 90px">
                  <v-autocomplete
                     v-model="selectCiclo"
                     label="Ciclo"
                     :items="cmbCiclosBecas"
                     no-data-text="Sin resultados"
                  >
                  </v-autocomplete>
               </div>
               <div
                  v-if="checkRangoAlto()"
                  class="mt-6 ml-3"
                  style="width: 120px"
               >
                  <v-autocomplete
                     v-model="selectEsReaplicacion"
                     label="Tipo"
                     item-value="id"
                     item-text="nombre"
                     :items="cmbEsReaplicacion"
                     no-data-text="Sin resultados"
                  >
                  </v-autocomplete>
               </div>
               <v-spacer></v-spacer>
               <v-text-field
                  v-if="
                     !showFilterAsesor &&
                     !showFilterBeneficio &&
                     !showFilterBeneficiario
                  "
                  ref="txtBusqueda"
                  v-model="searchComputed"
                  append-icon="fa-search"
                  class="mt-6"
                  color="white"
                  label="Busqueda"
                  single-line
               ></v-text-field>
               <v-autocomplete
                  v-if="checkRangoAlto() && showFilterAsesor"
                  v-model="selectAsesorFilter"
                  class="mt-6"
                  :items="cmbAsesores"
                  item-value="id"
                  item-text="userClaims[0].claimValue"
                  placeholder="Filtrar por asesor"
                  color="white"
               ></v-autocomplete>
               <v-autocomplete
                  v-if="checkRangoAlto() && showFilterBeneficio"
                  v-model="selectTipoBeneficio"
                  class="mt-6"
                  :items="cmbTipoBeneficio"
                  item-value="tipoBeneficioId"
                  item-text="nombre"
                  placeholder="Filtrar por beneficio"
                  color="white"
               ></v-autocomplete>
               <v-autocomplete
                  v-if="checkRangoAlto() && showFilterBeneficiario"
                  v-model="selectBeneficiarioFiltro"
                  class="mt-6"
                  :items="cmbBeneficiario"
                  item-value="beneficiarioId"
                  item-text="nombre"
                  placeholder="Filtrar por beneficiario"
                  color="white"
               ></v-autocomplete>
               <v-spacer></v-spacer>

               <v-btn
                  v-if="checkRangoAlto() && selectCiclo != cicloActual"
                  text
                  dark
                  @click="showDialogReaplicacion"
               >
                  Reaplicación <v-icon right>fa-redo-alt</v-icon>
               </v-btn>

               <v-btn
                  v-if="checkRangoAlto()"
                  text
                  dark
                  v-on="on"
                  @click="dialogRepGrupales = true"
               >
                  Reportes <v-icon right>fa-print</v-icon>
               </v-btn>

               <v-btn
                  v-if="checkModificarAsesor()"
                  text
                  dark
                  v-on="on"
                  @click="dialogoBloquear = true"
               >
                  Bloquear
                  <v-icon right color="white">fa-duotone fa-ban</v-icon>
               </v-btn>

               <v-dialog v-model="dialogoBloquear" width="290">
                  <v-card>
                     <v-card-title class="text-h6"> </v-card-title>

                     <v-card-text
                        class="text-center text-subtitle-1 font-weight-bold"
                     >
                        ¿Esta seguro que quiere bloquear todas las solicitudes
                        de becas?
                     </v-card-text>

                     <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="primary" text @click="bloquearbecas()">
                           Si
                        </v-btn>

                        <v-btn
                           color="primary"
                           text
                           @click="dialogoBloquear = false"
                        >
                           No
                        </v-btn>
                     </v-card-actions>
                  </v-card>
               </v-dialog>
            </v-toolbar>
            <!-- DATATABLE SOLICITUDES -->
            <v-data-table
               :headers="headers"
               :items="filteredSolicitudes"
               :loading="loadingSolicitudes"
               fixed-header
               :sort-by.sync="soliSortBy"
               :sort-desc.sync="soliSortDesc"
               :page.sync="soliPage"
               :items-per-page.sync="soliItemsPerPage"
               height="64vh"
               no-data-text="No se encontraron solicitudes de beca."
               loading-text="Listando solicitudes de beca, por favor espere..."
               :search="search"
               multi-sort
               :footer-props="footerByUser(oidcUser)"
               class="elevation-0 mt-3"
            >
               <!-- Filtros de busqueda -->
               <template #footer="{}">
                  <tr>
                     <td :colspan="headers.length">
                        <v-layout align-center justify-start fill-height>
                           <div class="mx-4">
                              <span class="text-body-1 font-weight-medium">
                                 Buscar por
                              </span>
                           </div>
                           <div style="margin-top: 5px">
                              <v-radio-group
                                 v-model="radiosBusqueda"
                                 row
                                 @change="cambiaRadios"
                              >
                                 <v-radio
                                    label="Carnet"
                                    color="primary"
                                    value="r-carnet"
                                 ></v-radio>
                                 <v-radio
                                    label="Nombres"
                                    color="primary"
                                    value="r-nombres"
                                 ></v-radio>
                                 <v-radio
                                    label="Apellidos"
                                    color="primary"
                                    value="r-apellidos"
                                 ></v-radio>
                                 <v-radio
                                    label="Carrera"
                                    color="primary"
                                    value="r-carrera"
                                 ></v-radio>
                                 <v-radio
                                    label="Beneficiario"
                                    color="primary"
                                    value="r-beneficiario"
                                 ></v-radio>
                                 <v-radio
                                    v-if="
                                       oidcUser.nivelasesor == 0 ||
                                       oidcUser.nivelasesor > 1 ||
                                       oidcUser.role == 'admin'
                                    "
                                    label="Tipo beneficio"
                                    color="primary"
                                    value="r-tipobeneficio"
                                 ></v-radio>
                                 <v-radio
                                    label="Fecha de solicitud"
                                    color="primary"
                                    value="r-fecha"
                                 ></v-radio>
                                 <v-radio
                                    v-if="
                                       oidcUser.nivelasesor == 0 ||
                                       oidcUser.nivelasesor > 1 ||
                                       oidcUser.role == 'admin'
                                    "
                                    label="Asesor"
                                    color="primary"
                                    value="r-asesor"
                                 ></v-radio>
                                 <v-radio
                                    label="Estado"
                                    color="primary"
                                    value="r-estado"
                                 ></v-radio>
                              </v-radio-group>
                           </div>
                        </v-layout>
                     </td>
                  </tr>
               </template>

               <template #item.prioridad="{ item }">
                  <v-tooltip
                     v-if="item.prioridad == 0"
                     bottom
                     max-width="300"
                     color="primary"
                  >
                     <template #activator="{ on }">
                        <v-icon size="18" color="primary" v-on="on"
                           >fas fa-circle</v-icon
                        >
                     </template>
                     <span style="font-size: 15px">Sin prioridad</span>
                  </v-tooltip>
                  <v-tooltip
                     v-if="item.prioridad == 1"
                     bottom
                     max-width="300"
                     color="amber darken-3"
                  >
                     <template #activator="{ on }">
                        <v-icon size="18" color="amber darken-3" v-on="on"
                           >fas fa-circle</v-icon
                        >
                     </template>
                     <span style="font-size: 15px">Segunda opción</span>
                  </v-tooltip>
                  <v-tooltip
                     v-if="item.prioridad == 2"
                     bottom
                     max-width="300"
                     color="green darken-2"
                  >
                     <template #activator="{ on }">
                        <v-icon size="18" color="green darken-2" v-on="on"
                           >fas fa-circle</v-icon
                        >
                     </template>
                     <span style="font-size: 15px">Candidato</span>
                  </v-tooltip>
               </template>
               <template #item.completada="{ item }">
                  <v-tooltip
                     v-if="item.completada"
                     bottom
                     max-width="300"
                     color="primary"
                  >
                     <template #activator="{ on }">
                        <v-icon
                           color="primary"
                           v-on="on"
                           @click="
                              checkRangoAlto()
                                 ? alternarCompletado(item)
                                 : false
                           "
                           >fas fa-check</v-icon
                        >
                     </template>
                     <span style="font-size: 15px"> Si </span>
                  </v-tooltip>
                  <v-tooltip v-else bottom max-width="300" color="primary">
                     <template #activator="{ on }">
                        <v-icon
                           color="primary"
                           v-on="on"
                           @click="
                              checkRangoAlto()
                                 ? alternarCompletado(item)
                                 : false
                           "
                           >fas fa-times</v-icon
                        >
                     </template>
                     <span style="font-size: 15px"> No </span>
                  </v-tooltip>
               </template>
               <template #item.notificado="{ item }">
                  <v-tooltip
                     v-if="item.notificado"
                     bottom
                     max-width="300"
                     color="primary"
                  >
                     <template #activator="{ on }">
                        <v-icon color="primary" v-on="on"
                           >fas fa-envelope-open-text</v-icon
                        >
                     </template>
                     <span style="font-size: 15px"> Enviada </span>
                  </v-tooltip>
                  <v-tooltip v-else bottom max-width="300" color="primary">
                     <template #activator="{ on }">
                        <v-icon
                           color="primary"
                           :disabled="
                              reenviando && reenviandoId == item.solicitudBecaId
                           "
                           v-on="on"
                           @click="
                              checkRangoAlto() && !reenviando
                                 ? reenviarSolicitud(item)
                                 : false
                           "
                        >
                           {{
                              reenviando && reenviandoId == item.solicitudBecaId
                                 ? 'fas fa-circle-notch fa-spin'
                                 : 'far fa-envelope'
                           }}
                        </v-icon>
                     </template>
                     <div style="font-size: 15px; text-align: center">
                        No se pudo enviar, <br />
                        has clic para reenviar
                     </div>
                  </v-tooltip>
               </template>
               <template #item.estadoCaso="{ item }">
                  <v-chip
                     :color="getColorEstadoSoli(item)"
                     dark
                     @click="getLog(item)"
                  >
                     <span>{{ item.estadoCaso }}</span>
                  </v-chip>
               </template>

               <template #item.asesorId="{ item }">
                  {{ getNombreAsesor(item) }}
               </template>

               <template #item.tipoBeneficioId="{ item }">
                  {{ getNombreBeneficio(item) }}
               </template>

               <template #item.opciones="{ item }">
                  <v-tooltip bottom max-width="300" color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="amber darken-3"
                           v-on="on"
                           @click="revisarSolicitud(item)"
                        >
                           <v-icon>fa-pen</v-icon>
                        </v-btn>
                     </template>
                     <span style="font-size: 15px"> Editar </span>
                  </v-tooltip>
               </template>
            </v-data-table>
         </v-card>
      </v-flex>
      <div class="text-center">
         <v-dialog v-model="logDialog" width="600">
            <v-card>
               <HistorialEstado :width="600" :estados="logs" />
               <v-divider></v-divider>
               <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="logDialog = false">
                     Salir
                  </v-btn>
               </v-card-actions>
            </v-card>
         </v-dialog>
      </div>
   </v-layout>
</template>

<script>
import axios from 'axios'
import debounce from 'lodash/debounce'
import { mask } from 'vue-the-mask'
import { authComputed } from '../helpers'
import formatters from '../mixins/formattersLib'
import FormBecasDatosGenerales from './FormularioBecasDatosGenerales'
import FormBecasLaboral from './FormularioBecasLaboral'
import FormBecasNucleoFamiliar from './FormularioBecasNucleoFamiliar'
import FormBecasBienesFamiliares from './FormularioBecasBienesFamiliares'
import FormBecasContIngresosEgresos from './FormularioBecasContIngresosEgresos'
import FormBecasEnsayo from './FormularioBecasEnsayo'
import FormBecasComprobantes from './FormularioBecasComprobantes'
import ReportesSoliBeca from './custom/ReportesSoliBeca'
import XLSX from 'xlsx'
import HistorialEstado from '@/components/HistorialEstado.vue'
import * as ReportesBecas from '@/reportes/cartas-becas'
const getReporteLib = () => import('../mixins/reporteLib')

export default {
   name: 'SolicitudesBecas',
   components: {
      FormBecasDatosGenerales,
      FormBecasLaboral,
      FormBecasNucleoFamiliar,
      FormBecasBienesFamiliares,
      FormBecasContIngresosEgresos,
      FormBecasEnsayo,
      FormBecasComprobantes,
      ReportesSoliBeca,
      HistorialEstado
   },
   directives: {
      mask
   },
   mixins: [formatters],
   data: () => ({
      loadingSolicitudes: false,
      showFilterAsesor: false,
      showFilterBeneficio: false,
      showFilterBeneficiario: false,
      dialogRepIndividuales: false,
      dialogRepGrupales: false,
      dialogoBloquear: false,
      dialogReaplicacion: false,
      editedIndex: -1,
      radioResumenGeneral: 'r-nofiltrar',
      selectCarrera: null,
      selectFacultad: null,
      cmbCarrera: [],
      cmbFacultad: [],
      cicloActual: '',
      reaplicacionOutput: '',
      loadingReaplicacion: false,
      reaplicacionCarnet: '',
      swReaplicarCarnet: false,
      headers: [
         {
            text: 'N° Caso',
            value: 'solicitudBecaId',
            width: 120,
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Comp.',
            value: 'completada',
            width: 100,
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Noti.',
            value: 'notificado',
            width: 100,
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Carnet',
            width: 130,
            value: 'carnet',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Nombres',
            value: 'nombres',
            class: 'titulo'
         },
         {
            text: 'Apellidos',
            value: 'apellidos',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Carrera',
            value: 'carreraUniversitaria',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Beneficiario',
            value: 'beneficiario',
            width: 150,
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Fecha',
            value: 'createOn',
            align: 'center',
            class: 'titulo',
            sort: (a, b) => {
               var date1 = new Date(
                  a.split(' ')[0].replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$2/$1')
               )
               var date2 = new Date(
                  b.split(' ')[0].replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$2/$1')
               )

               return date1 < date2 ? -1 : 1
            }
         },
         {
            text: 'Asesor',
            value: 'asesorId',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Estado',
            value: 'estadoCaso',
            align: 'center',
            class: 'titulo',
            width: 100
         },
         {
            text: '',
            value: 'prioridad',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Opciones',
            value: 'opciones',
            width: 100,
            sortable: false,
            align: 'right',
            class: 'titulo'
         }
      ],

      // Variables de formularios
      search: '',
      numeroCaso: 0,
      numeroRef: '',
      carreraId: 0,
      solicitudes: [],
      soliSortBy: [],
      soliSortDesc: [],
      soliPage: 1,
      soliItemsPerPage: 25,
      verNuevo: false,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',
      stepNum: 3,
      solicitudBecaId: null,
      solicitudBecaRef: null,
      solicitudBeneId: null,
      solicitudInfo: null,
      completada: false,
      carnet: '',
      selectBeneficiario: null,
      reenviando: false,
      reenviandoId: null,
      dialogReporteLoading: false,
      selectEsReaplicacion: null,
      cmbEsReaplicacion: [
         {
            id: null,
            nombre: 'Todas'
         },
         {
            id: false,
            nombre: 'Nuevo'
         },
         {
            id: true,
            nombre: 'Reaplicación'
         }
      ],

      // Propiedades formulario
      cmbCiclosBecas: [],
      radiosBusqueda: 'r-nombres',
      selectAsesor: '',
      selectBeneficiarioFiltro: '',
      cmbBeneficiario: [],
      selectTipoBeneficio: '',
      cmbTipoBeneficio: [],
      selectAsesorFilter: '',
      cmbAsesores: [],
      cmbUsuarios: [],
      selectCiclo: '',
      logs: [],
      logDialog: false
   }),
   computed: {
      ...authComputed,
      filteredSolicitudes() {
         switch (this.radiosBusqueda) {
            case 'r-nombres':
               return this.solicitudes.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.nombres)
                  )
               })
               break
            case 'r-carnet':
               return this.solicitudes.filter((i) => {
                  return (
                     !this.search || new RegExp(this.search, 'i').test(i.carnet)
                  )
               })
               break
            case 'r-apellidos':
               return this.solicitudes.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.apellidos)
                  )
               })
               break
            case 'r-fecha':
               return this.solicitudes.filter((i) => {
                  return (
                     !this.search || new RegExp(this.search, 'i').test(i.fecha)
                  )
               })
               break
            case 'r-carrera':
               return this.solicitudes.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.carreraUniversitaria)
                  )
               })
               break
            case 'r-estado':
               return this.solicitudes.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.estadoCaso)
                  )
               })
               break
            case 'r-asesor':
               return this.solicitudes.filter((i) => {
                  return (
                     !this.selectAsesorFilter ||
                     this.selectAsesorFilter == i.asesorId
                  )
               })
               break
            case 'r-beneficiario':
               return this.solicitudes.filter((i) => {
                  return (
                     !this.selectBeneficiarioFiltro ||
                     this.selectBeneficiarioFiltro == i.beneficiarioId
                  )
               })
               break
            case 'r-tipobeneficio':
               return this.solicitudes.filter((i) => {
                  return (
                     !this.selectTipoBeneficio ||
                     this.selectTipoBeneficio == i.tipoBeneficioId
                  )
               })
               break
            default:
               return this.solicitudes
         }
      },
      searchComputed: {
         get() {
            return this.search
         },
         set: debounce(function (newVal) {
            this.search = newVal.trim()
         }, 500)
      }
   },
   watch: {
      swReaplicarCarnet(val) {
         if (val == false) {
            this.reaplicacionCarnet = ''
         }
      },
      selectCiclo() {
         this.listarSolicitudes()
      },
      selectEsReaplicacion() {
         this.listarSolicitudes()
      },
      radioResumenGeneral() {
         this.selectCarrera = null
         this.selectFacultad = null
      },
      dialogRepGrupales() {
         this.radioResumenGeneral = 'r-nofiltrar'
      }
   },
   created() {
      // Validando acceso al componente
      if (this.permController(9, 'ver')) {
         this.listarComboboxes()
      } else {
         this.$router.push({ name: 'inicio' })
      }
   },
   methods: {
      reaplicarCarnet() {
         this.loadingReaplicacion = true
         this.reaplicacionOutput = ''
         axios
            .post('api/SolicitudDeBeca/Reapliacion', {
               cicloOrigen: this.selectCiclo,
               cicloDestino: this.cicloActual,
               carnet: this.reaplicacionCarnet
            })
            .then((response) => {
               this.loadingReaplicacion = false
               var res = response.data
               var txt = ''
               if (res.length == 0) {
                  this.reaplicacionOutput = 'No se reaplicó la solicitud.'
               } else {
                  var cont = 0
                  res.forEach((el) => {
                     cont++
                     txt = txt.concat(
                        `${el.solicitudBecaId} - ${el.carnet}, Notificación: ${
                           el.notificado ? 'Enviada' : 'No enviada'
                        }\n`
                     )
                  })
                  txt = txt.concat(`\n${cont} solicitud reaplicada.`)
                  this.reaplicacionOutput = txt
               }
            })
            .catch(() => {
               this.loadingReaplicacion = false
               //console.log(error)
            })
      },
      reaplicarCiclo() {
         this.loadingReaplicacion = true
         this.reaplicacionOutput = ''
         axios
            .post('api/SolicitudDeBeca/Reapliacion', {
               cicloOrigen: this.selectCiclo,
               cicloDestino: this.cicloActual
            })
            .then((response) => {
               this.loadingReaplicacion = false
               var res = response.data
               var txt = ''
               if (res.length == 0) {
                  this.reaplicacionOutput =
                     'No se reaplicaron nuevas solicitudes.'
               } else {
                  var cont = 0
                  res.forEach((el) => {
                     cont++
                     txt = txt.concat(
                        `${el.solicitudBecaId} - ${el.carnet}, Notificación: ${
                           el.notificado ? 'Enviada' : 'No enviada'
                        }\n`
                     )
                  })
                  txt = txt.concat(`\n${cont} solicitudes reaplicadas.`)
                  this.reaplicacionOutput = txt
               }
            })
            .catch(() => {
               this.loadingReaplicacion = false
               //console.log(error)
            })
      },
      showDialogReaplicacion() {
         this.dialogReaplicacion = true
      },
      closeDialogReaplicacion() {
         this.reaplicacionOutput = ''
         this.swReaplicarCarnet = false
         this.dialogReaplicacion = false
      },
      reporteResumenGeneral(carreraId = null, facultadId = null) {
         this.dialogReporteLoading = true
         var resPago = []
         var queryResult =
            'api/SolicitudDeBeca/Resumen?PeriodoLectivo=' + this.selectCiclo

         if (carreraId != null && facultadId == null) {
            queryResult = queryResult.concat('&CarreraId=' + this.selectCarrera)
         }
         if (carreraId == null && facultadId != null) {
            queryResult = queryResult.concat(
               '&FacultadId=' + this.selectFacultad
            )
         }

         axios
            .get(queryResult)
            .then((response) => {
               resPago = response.data
            })
            .catch((error) => {
               console.log(error)
               this.dialogReporteLoading = false
            })
            .finally(() => {
               // Generar excel
               var heading = [
                  [
                     'Nombre',
                     'Carnet',
                     'N° de aplicaciones',
                     'N° de ciclos de ser becario',
                     'Facultad',
                     'Carrera',
                     'Ciclo',
                     'Dirección',
                     'N° de miembros en familia',
                     'Salario',
                     'Otros ingresos',
                     'Energía eléctrica',
                     'Agua',
                     'Teléfono / Internet',
                     'Vivienda',
                     'Otros egresos',
                     'Total ingresos',
                     'Total egresos',
                     'Observaciones asesor',
                     'Observaciones administrador',
                     'Estado',
                     'CUM'
                  ]
               ]

               var today = new Date()
               //today = today.substring(0, 16)
               var filename = 'Resumen general ' + today + '.xlsx'
               var wb = XLSX.utils.book_new()

               // Agregando headers y data de arreglo
               var ws = XLSX.utils.aoa_to_sheet(heading, { origin: 'A1' })
               XLSX.utils.sheet_add_json(ws, resPago, {
                  skipHeader: true,
                  origin: 'A2'
               })
               XLSX.utils.sheet_add_aoa(ws, [['Ingresos']], {
                  origin: 'J1'
               })
               XLSX.utils.sheet_add_aoa(ws, [['Egresos']], {
                  origin: 'L1'
               })

               // Get worksheet range
               var range = XLSX.utils.decode_range(ws['!ref'])

               for (var R = range.s.r; R <= range.e.r; ++R) {
                  for (var C = 7; C <= 19; C++) {
                     if (R >= 2 && (C == 7 || C == 18 || C == 19)) {
                        var cell_address = { c: C, r: R }
                        var cell_ref = XLSX.utils.encode_cell(cell_address)

                        // Cambiando valor actual por comentario
                        var oldValue = ws[cell_ref].v
                        if (oldValue == null || oldValue.trim() == '') {
                           ws[cell_ref].v = ''
                        } else {
                           ws[cell_ref].v =
                              C == 7 ? 'Ver dirección' : 'Ver comentario'
                           ws[cell_ref].c = []
                           ws[cell_ref].c.hidden = true
                           ws[cell_ref].c.push({
                              a: 'Sistema',
                              t: oldValue
                           })
                        }
                     }
                  }
               }

               /* Testing comment
               ws['A4'].c = []
               ws['A4'].c.hidden = true
               ws['A4'].c.push({ a: 'SheetJS', t: 'This comment is hidden' }) */

               const merge = [
                  { s: { r: 0, c: 9 }, e: { r: 0, c: 10 } },
                  { s: { r: 0, c: 11 }, e: { r: 0, c: 15 } }
               ]
               ws['!merges'] = merge

               // Asignando ancho de columnas segun contenido
               resPago.forEach((el) => {
                  el.observacionAsesor = 'Ver comentario'
                  el.observacionAdministrador = 'Ver comentario'
                  el.ciudadDeProcedencia = 'Ver direccion'
               })
               this.autofitColumns(resPago, ws)

               // Agregando hoja y exportando archivo
               XLSX.utils.book_append_sheet(wb, ws, 'Reporte')
               XLSX.writeFile(wb, filename)
               this.dialogReporteLoading = false
            })
      },
      reporteExcelSolicitudes() {
         this.dialogReporteLoading = true
         var resPago = []

         axios
            .get(
               'api/SolicitudDeBeca/reporte?cicloDeAplicacion=' +
                  this.selectCiclo
            )
            .then((response) => {
               resPago = response.data
            })
            .catch((error) => {
               console.log(error)
               this.dialogReporteLoading = false
            })
            .finally(() => {
               // Generar excel
               var heading = [
                  [
                     'N° Caso',
                     'Carnet',
                     'Nombre',
                     'Periodo Lectivo',
                     'Categoria',
                     'Descripcion',
                     'Carrera',
                     'Beneficiario',
                     'Reaplicacion',
                     'Estado',
                     'Correo',
                     'Telefono Principal',
                     'Telefono Secundario',
                     'BienestarUniversitario',
                     'CUM',
                     'Congregacion',
                     'Institucion Patrocinadora'
                  ]
               ]
               var today = new Date()
               //today = today.substring(0, 16)
               var filename = 'Solicitudes de beca ' + today + '.xlsx'
               var wb = XLSX.utils.book_new()

               // Agregando headers y data de arreglo
               var ws = XLSX.utils.aoa_to_sheet(heading, { origin: 'A1' })
               XLSX.utils.sheet_add_json(ws, resPago, {
                  skipHeader: true,
                  origin: 'A2'
               })

               // Asignando ancho de columnas segun contenido
               this.autofitColumns(resPago, ws)

               // Agregando hoja y exportando archivo
               XLSX.utils.book_append_sheet(wb, ws, 'Reporte')
               XLSX.writeFile(wb, filename)
               this.dialogReporteLoading = false
            })
      },
      reenviarSolicitud(item) {
         this.reenviando = true
         this.reenviandoId = item.solicitudBecaId
         axios
            .post(
               'api/SolicitudDeBeca/ReenviarNotificacion/' +
                  item.solicitudBecaId
            )
            .then(() => {
               this.snackText = `Notificación reenviada a ${item.email}!`
               this.snackColor = 'snackSuccess'
               this.snackbar = true
               setTimeout(() => {
                  this.reenviando = false
                  this.reenviandoId = null
                  this.listarSolicitudes()
               }, 500)
            })
            .catch(() => {
               this.snackText =
                  'No fue posible reenviar la notificación, por favor intente de nuevo en unos minutos.'
               this.snackColor = 'snackError'
               this.snackbar = true
               this.reenviando = false
               this.reenviandoId = null
            })
      },
      alternarCompletado(item) {
         axios
            .put('api/SolicitudDeBeca/completar/' + item.solicitudBecaId, {
               completar: !item.completada
            })
            .then(() => {
               this.snackText = 'Estado de completado actualizado'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
               setTimeout(() => {
                  this.listarSolicitudes()
               }, 500)
            })
            .catch(() => {
               this.snackText = 'Error al actualizar estado de completado'
               this.snackColor = 'snackError'
               this.snackbar = true
            })
      },
      regresar() {
         this.verNuevo = false
         this.solicitudBecaId = null
         this.listarSolicitudes()
      },
      nextStepDatosGenerales(soliInfo) {
         this.solicitudBecaId = soliInfo.solicitudBecaId
         this.selectBeneficiario = soliInfo.beneficiarioId
         this.completada = soliInfo.completada
         this.solicitudInfo = soliInfo

         // Si ya esta cargada una solicitud, solo pasar al siguiente step
         if (
            soliInfo.beneficiarioId == 1 ||
            soliInfo.beneficiarioId == 2 ||
            soliInfo.beneficiarioId == 4 ||
            soliInfo.beneficiarioId == 8 ||
            soliInfo.beneficiarioId == 9
         ) {
            this.stepNum = 3.1
         } else {
            this.stepNum = 4
         }
      },
      revisarSolicitud(item) {
         // Campos para dialogo de reportes
         this.numeroCaso = item.solicitudBecaId
         this.numeroRef = item.numeroReferencia
         this.carreraId = item.carreraUniversitariaId
         this.completada = item.completada
         this.selectBeneficiario = item.beneficiarioId

         // Consultando informacion de solicitud
         this.solicitudBeneId = null
         axios
            .get('api/SolicitudDeBeca?numeroRef=' + item.numeroReferencia)
            .then((response) => {
               this.stepNum = 3
               this.verNuevo = true
               setTimeout(() => {
                  this.solicitudInfo = response.data[0]
                  this.solicitudBecaId = this.solicitudInfo.solicitudBecaId
                  this.solicitudBecaRef = this.solicitudInfo.numeroReferencia
                  this.solicitudBeneId = this.solicitudInfo.beneficiarioId
               }, 800)
            })

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      getLog(item) {
         axios
            .get('/api/SolicitudBecaLog/' + item.numeroReferencia)
            .then((response) => {
               this.logs = response.data
               this.logDialog = true
            })
      },
      footerByUser(user) {
         if (
            user.nivelasesor == 0 ||
            user.nivelasesor > 1 ||
            user.role == 'admin'
         ) {
            return {
               itemsPerPageOptions: [25, 50, 75],
               itemsPerPageText: 'Filas por página:'
            }
         } else {
            return {
               itemsPerPageOptions: [10, 25],
               itemsPerPageText: 'Filas por página:'
            }
         }
      },
      cambiaRadios() {
         if (this.radiosBusqueda == 'r-asesor') {
            this.showFilterAsesor = true
            this.showFilterBeneficio = false
            this.showFilterBeneficiario = false
            this.selectAsesorFilter = ''
            this.search = ''
         } else if (this.radiosBusqueda == 'r-tipobeneficio') {
            this.showFilterAsesor = false
            this.showFilterBeneficio = true
            this.showFilterBeneficiario = false
            this.selectTipoBeneficio = ''
            this.search = ''
         } else if (this.radiosBusqueda == 'r-beneficiario') {
            this.showFilterAsesor = false
            this.showFilterBeneficio = false
            this.showFilterBeneficiario = true
            this.selectBeneficiarioFiltro = ''
            this.search = ''
         } else {
            this.showFilterAsesor = false
            this.showFilterBeneficio = false
            this.showFilterBeneficiario = false
            this.selectAsesorFilter = ''
            this.selectTipoBeneficio = ''
            setTimeout(() => {
               this.focusBusqueda()
            }, 300)
         }
      },
      checkRangoAlto() {
         if (this.oidcUser) {
            return Number(this.oidcUser.nivelasesor) > 1 ||
               Number(this.oidcUser.nivelasesor) == 0 ||
               this.oidcUser.role == 'admin'
               ? true
               : false
         } else {
            return false
         }
      },
      checkModificarAsesor() {
         if (this.oidcUser !== null) {
            return this.oidcUser.role === 'admin' ||
               this.oidcUser.nivelasesor === '0'
               ? true
               : false
         } else {
            return false
         }
      },

      getNombreAsesor(item) {
         if (item != undefined && this.cmbUsuarios.length > 0) {
            return this.cmbUsuarios.find((obj) => {
               return obj.id === item.asesorId
            })
               ? this.cmbUsuarios.find((obj) => {
                    return obj.id === item.asesorId
                 }).userClaims[0].claimValue
               : 'Administrador'
         }
      },
      getNombreBeneficio(item) {
         if (item != undefined && this.cmbTipoBeneficio.length > 0) {
            return this.cmbTipoBeneficio.find((obj) => {
               return obj.tipoBeneficioId === item.tipoBeneficioId
            })
               ? this.cmbTipoBeneficio.find((obj) => {
                    return obj.tipoBeneficioId === item.tipoBeneficioId
                 }).nombre
               : ''
         }
      },
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Popular el datatable
      listarSolicitudes() {
         this.loadingSolicitudes = true
         // Listar todos las solicitudes solo si es admin o nivel 2
         if (this.checkRangoAlto()) {
            var filtroString =
               this.selectEsReaplicacion == null
                  ? ''
                  : '&Reapliacion=' + this.selectEsReaplicacion
            axios
               .get(
                  'api/SolicitudDeBeca?CicloDeAplicacion=' +
                     this.selectCiclo +
                     filtroString
               )
               .then((response) => {
                  this.solicitudes = response.data
               })
               .catch(() => {
                  //console.log(error)
               })
               .finally(() => {
                  this.loadingSolicitudes = false
               })
         } else {
            axios
               .get(
                  'api/SolicitudDeBeca?AsesorId=' +
                     this.oidcUser.sub +
                     '&CicloDeAplicacion=' +
                     this.selectCiclo
               )
               .then((response) => {
                  this.solicitudes = response.data
               })
               .catch(() => {
                  //console.log(error)
               })
               .finally(() => {
                  this.loadingSolicitudes = false
               })
         }
      },
      listarComboboxes() {
         axios
            .get('api/Configuracion/cicloActual')
            .then((response) => {
               this.cicloActual = response.data.valor
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/SolicitudDeBeca/ciclosDisponibles')
            .then((response) => {
               this.cmbCiclosBecas = response.data
               this.selectCiclo = response.data[response.data.length - 1]
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/general/CarreraUniversitaria')
            .then((response) => {
               this.cmbCarrera = response.data
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/general/UnidadAcademica')
            .then((response) => {
               this.cmbFacultad = response.data
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/solicitudesdebecas/Beneficiario')
            .then((response) => {
               this.cmbBeneficiario = response.data
            })
            .catch(() => {
               //console.log(error)
            })
         axios({
            method: 'get',
            url: 'api/UserManagement/clienteweb?soloAsesores=true',
            baseURL: process.env.VUE_APP_IDENTITY
         }).then((response) => {
            this.cmbAsesores = response.data
            //console.log(me.usuarios)
         })
         axios({
            method: 'get',
            url: 'api/UserManagement/clienteweb',
            baseURL: process.env.VUE_APP_IDENTITY
         }).then((response) => {
            this.cmbUsuarios = response.data
            //console.log(me.usuarios)
         })
         axios
            .get('api/Beca/TipoBeneficio')
            .then((response) => {
               this.cmbTipoBeneficio = response.data.map((beneficio) => {
                  return {
                     tipoBeneficioId: beneficio.id,
                     nombre: beneficio.nombre
                  }
               })
            })
            .catch((error) => console.log(error))
      },
      // Si no cargaron bien las solicitudes en el datatable se puede resetear
      reset() {
         this.listarSolicitudes()
      },
      getColorEstadoSoli(item) {
         switch (item.estadoCasoId) {
            case 1:
               return 'blue'
               break
            case 2:
               return 'amber darken-2'
               break
            case 3:
               return 'green'
               break
            case 4:
               return 'red darken-3'
               break
            case 5:
               return 'grey darken-3'
               break
            case 8:
               return 'teal'
               break
            case 9:
               return 'brown darken-1'
               break
            case 10:
               return 'lime darken-2'
               break
            default:
               return 'grey darken-1'
               break
         }
      },
      bloquearbecas() {
         this.dialogoBloquear = false
         console.log(this.selectCiclo)
         axios
            .put(
               `/api/SolicitudDeBeca/BloqueoTotal?bloquear=true&cicloDeAplicacion= ${this.selectCiclo}`
            )
            .then((response) => {
               console.log(response.status)
               if (response.status === 200) {
                  this.snackText = `Las solicitudes han sido bloqueadas exitosamente`
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true

                  setTimeout(() => {
                     this.snackbar = false
                  }, 5000)
               }
            })
            .catch(() => {
               this.snackText = `Las solicitudes se encuentran bloqueadas`
               this.snackColor = 'snackError'
               this.snackbar = true

               setTimeout(() => {
                  this.snackbar = false
               }, 5000)
            })
      },
      generarCartasBecarios() {
         axios
            .get('api/Beca/Reportes/Cartas?periodoLectivo=' + this.selectCiclo)
            .then((response) => {
               let cartas = response.data
               if (cartas.length === 0) {
                  this.snackText = 'No hay becas aplicadas'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true
                  setTimeout(() => {
                     this.snackbar = false
                  }, 5000)
                  return
               }
               let content = []
               cartas.forEach((carta) => {
                  if (
                     carta.tipoBeneficio === 'Administrativo' &&
                     carta.esPostGrado === true &&
                     carta.reaplicacion === true
                  ) {
                     content.push(
                        ReportesBecas.dictamenAdminNuevaPostgrado(carta)
                     )
                  } else if (
                     carta.tipoBeneficio === 'Administrativo' &&
                     carta.reaplicacion === false
                  ) {
                     content.push(ReportesBecas.dictamenAdminNueva(carta))
                  } else if (
                     carta.tipoBeneficio === 'Administrativo' &&
                     carta.reaplicacion === true
                  ) {
                     content.push(ReportesBecas.dictamenAdminRenovacion(carta))
                  } else if (
                     carta.tipoBeneficio === 'Servicio Estudiantil' &&
                     carta.estadoCaso === 'Denegado'
                  ) {
                     content.push(
                        ReportesBecas.dictamenServicioEstudiantilDenegada(carta)
                     )
                  } else if (
                     carta.tipoBeneficio === 'Servicio Estudiantil' &&
                     carta.reaplicacion === false
                  ) {
                     content.push(
                        ReportesBecas.dictamenServicioEstudiantilNueva(carta)
                     )
                  } else if (
                     carta.tipoBeneficio === 'Servicio Estudiantil' &&
                     carta.reaplicacion === true
                  ) {
                     content.push(
                        ReportesBecas.dictamenServicioEstudiantilrenovacion(
                           carta
                        )
                     )
                  }
               })

               let dd = {
                  pageSize: 'LETTER',
                  content: content,
                  styles: {
                     headerMain: {
                        fontSize: 13,
                        bold: true,
                        margin: [30, 15]
                     },
                     header: {
                        fontSize: 13,
                        bold: true,
                        margin: [30, 40]
                     },
                     texto: {
                        alignment: 'justify',
                        fontSize: 10,
                        margin: [0, 20],
                        bold: false
                     },
                     subheader: {
                        fontSize: 12,
                        bold: true,
                        margin: [0, -4]
                     },
                     subheader1: {
                        fontSize: 12,
                        bold: true,
                        margin: [0, -15]
                     },
                     subheader3: {
                        fontSize: 12,
                        bold: true,
                        margin: [0, 10]
                     },
                     infoAlum: {
                        fontSize: 12,
                        bold: true,
                        margin: [0, 2]
                     },
                     infoAlumno: {
                        fontSize: 11,
                        bold: true,
                        margin: [0, 1]
                     },
                     espaciado: {
                        margin: [0, 50]
                     },
                     final: {
                        fontSize: 9
                     },
                     footer: {
                        fontSize: 9,
                        margin: [0, 25, 0, 0]
                     },
                     footer1: {
                        fontSize: 9,
                        margin: [0, 0, 0, 0]
                     },
                     tableExample: {
                        margin: [175, 50, 1, 40],
                        fontSize: 9
                     }
                  },
                  defaultStyle: {
                     columnGap: 20
                  }
               }
               var filename = 'Cartas.pdf'
               getReporteLib().then((res) =>
                  res.generarPDF('open', dd, filename)
               )
            })
      }
   }
}
</script>
