<template>
   <v-dialog v-model="show" width="500" persistent>
      <v-card>
         <v-card-text class="text--primary" style="margin-top: -20px">
            <v-container grid-list-md fluid>
               <v-layout wrap>
                  <v-flex xs12 mt-4>
                     <span class="text-h5 font-weight-medium">
                        Reportes individuales
                     </span>
                  </v-flex>
                  <v-flex xs12 mt-2>
                     <v-btn
                        color="primary"
                        block
                        @click="reporteResumenIndividual"
                     >
                        <v-icon left>fa-file-pdf</v-icon>
                        Resumen individual
                     </v-btn>
                  </v-flex>
               </v-layout>
            </v-container>
         </v-card-text>

         <v-card-actions
            style="margin-top: -20px; margin-right: 20px; padding-bottom: 20px"
         >
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click.stop="close">Salir</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import axios from 'axios'
import formatters from '../../mixins/formattersLib'
const getReporteLib = () => import('../../mixins/reporteLib')

export default {
   name: 'ReportesSoliBeca',

   mixins: [formatters],
   props: {
      soliBecaId: {
         type: Number,
         default: null
      },
      carreraId: {
         type: Number,
         default: null
      },
      numeroRef: {
         type: String,
         default: null
      },
      value: Boolean
   },
   data: () => ({
      // Variables
      solicitudInfo: null,
      carreraInfo: null,
      laboralInfo: [],
      nucleoInfo: [],
      bienesInfo: null,
      ingresosInfo: [],
      egresosInfo: []
   }),
   computed: {
      show: {
         get() {
            return this.value
         },
         set(value) {
            var me = this
            me.$emit('input', value)
         }
      }
   },
   watch: {},
   methods: {
      async getData() {
         var me = this

         const reqSoliInfo = axios.get(
            'api/SolicitudDeBeca?numeroRef=' + me.numeroRef
         )
         const reqCarreraInfo = axios.get(
            'api/general/CarreraUniversitaria/' + me.carreraId
         )
         const reqLaboralInfo = axios.get(
            'api/solicitudesdebecas/' + me.soliBecaId + '/DatoLaboralFamiliar'
         )
         const reqNucleoInfo = axios.get(
            'api/solicitudesdebecas/' + me.soliBecaId + '/NucleoFamiliar'
         )
         const reqBienesInfo = axios.get(
            'api/solicitudesdebecas/' + me.soliBecaId + '/BienesFamiliares'
         )
         const reqIngresosInfo = axios.get(
            'api/solicitudesdebecas/' + me.soliBecaId + '/SolicitudIngreso'
         )
         const reqEgresosInfo = axios.get(
            '/api/solicitudesdebecas/' + me.soliBecaId + '/SolicitudEgreso'
         )

         await axios
            .all([
               reqSoliInfo,
               reqCarreraInfo,
               reqLaboralInfo,
               reqNucleoInfo,
               reqBienesInfo,
               reqIngresosInfo,
               reqEgresosInfo
            ])
            .then(
               axios.spread((...responses) => {
                  console.log(responses)
                  me.solicitudInfo = responses[0].data[0]
                  me.carreraInfo = responses[1].data
                  me.laboralInfo = responses[2].data
                  me.nucleoInfo = responses[3].data
                  me.bienesInfo = responses[4].data
                  me.ingresosInfo = responses[5].data
                  me.egresosInfo = responses[6].data
               })
            )
            .catch(() => {
               // react on errors.
            })
            .finally(() => {
               return 'Ok'
            })
      },
      reporteResumenIndividual() {
         var me = this
         me.getData().then(() => {
            var dd = {
               // Tamaño de pagina
               pageSize: 'LETTER',
               pageOrientation: me.checkOrientacion,
               pageMargins: [40, 20],
               defaultStyle: {
                  fontSize: 11
               },
               styles: {
                  defTable: {
                     fontSize: 10
                  }
               },
               // Pie de pagina con numero de páginas
               footer: function (currentPage, pageCount) {
                  return {
                     margin: [20, 0],
                     fontSize: 10,
                     alignment: 'right',
                     text:
                        'Generado: ' +
                        new Date().toLocaleString('es-ES', {
                           year: '2-digit',
                           month: '2-digit',
                           day: '2-digit',
                           hour: '2-digit',
                           minute: '2-digit',
                           hour12: 'true'
                        }) +
                        ' - Página ' +
                        currentPage.toString() +
                        ' de ' +
                        pageCount
                  }
               },
               content: [
                  {
                     text: `RESUMEN INDIVIDUAL, CASO N° ${me.solicitudInfo.solicitudBecaId}\n\n\n\n`,
                     bold: true,
                     alignment: 'center'
                  },
                  {
                     text: [
                        {
                           text: 'Nombre completo: ',
                           bold: true
                        },
                        me.solicitudInfo.nombres.trim() +
                           ' ' +
                           me.solicitudInfo.apellidos.trim() +
                           '\n\n'
                     ]
                  },
                  {
                     text: [
                        { text: 'Fecha de nacimiento: ', bold: true },
                        me.solicitudInfo.fechaDeNacimiento + '\n\n'
                     ]
                  },
                  {
                     text: [
                        { text: 'Edad: ', bold: true },
                        me.getAge(
                           me.parseDate(me.solicitudInfo.fechaDeNacimiento)
                        ) + '\n\n'
                     ]
                  },
                  {
                     text: [
                        { text: 'N° de carnet: ', bold: true },
                        me.solicitudInfo.carnet + '\n\n'
                     ]
                  },
                  {
                     text: [
                        { text: 'Facultad: ', bold: true },
                        me.carreraInfo.unidadAcademica + '\n\n'
                     ]
                  },
                  {
                     text: [
                        { text: 'Carrera: ', bold: true },
                        me.carreraInfo.nombre + '\n\n'
                     ]
                  },
                  {
                     text: [
                        {
                           text: 'Teléfono principal: ',
                           bold: true
                        },
                        me.solicitudInfo.telefonoPrincipal + '\n\n'
                     ]
                  },
                  {
                     text: [
                        {
                           text: 'Teléfono secundario: ',
                           bold: true
                        },
                        me.solicitudInfo.telefonoSecundario + '\n\n'
                     ]
                  },
                  {
                     text: [
                        {
                           text: 'Correo electrónico: ',
                           bold: true
                        },
                        me.solicitudInfo.email + '\n\n'
                     ]
                  },
                  {
                     text: [
                        {
                           text: 'Dirección de residencia: ',
                           bold: true
                        },
                        me.solicitudInfo.direccion + '\n\n'
                     ]
                  },
                  {
                     text: [
                        { text: 'Estado civil: ', bold: true },
                        me.solicitudInfo.estadoCivil + '\n\n'
                     ]
                  },
                  {
                     text: [
                        {
                           text: 'Número de hijos: ',
                           bold: true
                        },
                        me.solicitudInfo.hijos + '\n\n'
                     ]
                  },
                  {
                     text: 'Datos laborales: \n\n',
                     bold: true
                  },
                  {
                     style: 'defTable',
                     table: {
                        dontBreakRows: true,
                        widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto'],
                        body: buildDatosLaborales(me.laboralInfo)
                     }
                  },
                  '\n\n',
                  {
                     text: 'Núcleo familiar: \n\n',
                     bold: true
                  },
                  {
                     style: 'defTable',
                     table: {
                        dontBreakRows: true,
                        widths: ['50%', '*', '*'],
                        body: buildNucleoFamiliar(me.nucleoInfo)
                     }
                  },
                  '\n\n',
                  {
                     text: [
                        {
                           text: 'Material de la vivienda: ',
                           bold: true
                        },
                        me.bienesInfo.materialVivienda + '\n\n'
                     ]
                  },
                  {
                     text: [
                        {
                           text: 'Tipo de vivienda: ',
                           bold: true
                        },
                        me.bienesInfo.tipoVivienda + '\n\n'
                     ]
                  },
                  {
                     text: [
                        {
                           text: 'Datos del vehiculo: ',
                           bold: true
                        },
                        me.bienesInfo.tipoDeVehiculo == null
                           ? 'No posee'
                           : `\n• Tipo: ${me.bienesInfo.tipoDeVehiculo}\n• Número de placa: ${me.bienesInfo.numeroDePlaca}\n• Modelo: ${me.bienesInfo.modelo}\n• Año: ${me.bienesInfo.annio}`,
                        '\n\n'
                     ]
                  },
                  { text: 'Ingresos:\n\n', bold: true },
                  {
                     style: 'defTable',
                     table: {
                        dontBreakRows: true,
                        widths: ['auto', '*', '*'],
                        body: buildIngresos(me.ingresosInfo)
                     }
                  },
                  '\n\n',
                  { text: 'Egresos:\n\n', bold: true },
                  {
                     style: 'defTable',
                     table: {
                        dontBreakRows: true,
                        widths: ['auto', '*', '*'],
                        body: buildEgresos(me.egresosInfo)
                     }
                  },
                  '\n\n',
                  {
                     table: {
                        dontBreakRows: true,
                        widths: ['*'],
                        body: [
                           [
                              {
                                 text: [
                                    {
                                       text: 'Comentarios de asesor:',
                                       bold: true
                                    }
                                 ]
                              }
                           ],
                           [me.solicitudInfo.comentarioAsesor.trim()]
                        ]
                     }
                  },
                  '\n\n',
                  {
                     table: {
                        dontBreakRows: true,
                        widths: ['*'],
                        body: [
                           [
                              {
                                 text: [
                                    {
                                       text: 'Comentarios de administrador:',
                                       bold: true
                                    }
                                 ]
                              }
                           ],
                           [me.solicitudInfo.comentarioAdministrador.trim()]
                        ]
                     }
                  },
                  '\n\n'
               ]
            }

            function buildDatosLaborales(data) {
               var tempRow = []
               // Encabezado
               tempRow.push([
                  {
                     text: 'Nombre',
                     bold: true
                  },
                  {
                     text: 'Parentesco',
                     bold: true
                  },
                  {
                     text: 'Lugar de trabajo',
                     bold: true
                  },
                  {
                     text: 'Tiempo laboral (meses)',
                     bold: true
                  },
                  {
                     text: 'Salario',
                     alignment: 'right',
                     bold: true
                  },
                  {
                     text: 'Nombre de jefe',
                     bold: true
                  }
               ])

               if (data.length > 0) {
                  data.forEach((el) => {
                     tempRow.push([
                        {
                           text: el.nombre
                        },
                        {
                           text: el.parentesco
                        },
                        {
                           text: el.lugarDeTrabajo
                        },
                        {
                           text: el.tiempoLaboral
                        },
                        {
                           text: '$' + me.numFormatIntl(el.salario),
                           alignment: 'right'
                        },
                        {
                           text: el.jefeInmediato
                        }
                     ])
                  })
               } else {
                  tempRow.push([
                     {
                        text: 'Sin datos laborales',
                        alignment: 'center',
                        colSpan: 6
                     },
                     {},
                     {},
                     {},
                     {},
                     {}
                  ])
               }

               return tempRow
            }

            function buildNucleoFamiliar(data) {
               var tempRow = []
               // Encabezado
               tempRow.push([
                  {
                     text: 'Nombre',
                     bold: true
                  },
                  {
                     text: 'Profesión',
                     bold: true
                  },
                  {
                     text: 'Parentesco',
                     bold: true
                  }
               ])

               if (data.length > 0) {
                  data.forEach((el) => {
                     tempRow.push([
                        {
                           text: el.nombre
                        },
                        {
                           text: el.profesion
                        },
                        {
                           text: el.parentesco
                        }
                     ])
                  })
               } else {
                  tempRow.push([
                     {
                        text: 'Sin datos de núcleo familiar',
                        alignment: 'center',
                        colSpan: 3
                     },
                     {},
                     {}
                  ])
               }

               return tempRow
            }

            function buildIngresos(data) {
               var tempRow = []
               // Encabezado
               tempRow.push([
                  {
                     text: 'Tipo de ingreso',
                     bold: true
                  },
                  {
                     text: 'Descripción',
                     bold: true
                  },
                  {
                     text: 'Monto',
                     alignment: 'right',
                     bold: true
                  }
               ])

               if (data.length > 0) {
                  var totalFinal = 0
                  data.forEach((el) => {
                     tempRow.push([
                        {
                           text: el.ingreso
                        },
                        {
                           text: el.descripcion
                        },
                        {
                           text: el.monto,
                           alignment: 'right'
                        }
                     ])
                     totalFinal = Number(totalFinal) + Number(el.monto)
                  })

                  // Agregando row de total
                  tempRow.push([
                     {
                        text: 'TOTAL',
                        alignment: 'right',
                        bold: true,
                        colSpan: 2
                     },
                     {},
                     {
                        table: {
                           widths: [20, '*'],
                           body: [
                              [
                                 { text: '$', alignment: 'center', bold: true },
                                 {
                                    text: me.numFormatIntl(totalFinal),
                                    bold: true,
                                    alignment: 'right'
                                 }
                              ]
                           ]
                        },
                        layout: 'noBorders',
                        margin: [0, 0, 2, 0]
                     }
                  ])
               } else {
                  tempRow.push([
                     {
                        text: 'Sin datos de ingresos',
                        alignment: 'center',
                        colSpan: 3
                     },
                     {},
                     {}
                  ])
               }

               return tempRow
            }

            function buildEgresos(data) {
               var tempRow = []
               // Encabezado
               tempRow.push([
                  {
                     text: 'Tipo de egreso',
                     bold: true
                  },
                  {
                     text: 'Descripción',
                     bold: true
                  },
                  {
                     text: 'Monto',
                     alignment: 'right',
                     bold: true
                  }
               ])

               if (data.length > 0) {
                  var totalFinal = 0
                  data.forEach((el) => {
                     tempRow.push([
                        {
                           text: el.egreso
                        },
                        {
                           text: el.descripcion
                        },
                        {
                           text: el.monto,
                           alignment: 'right'
                        }
                     ])
                     totalFinal = Number(totalFinal) + Number(el.monto)
                  })

                  // Agregando row de total
                  tempRow.push([
                     {
                        text: 'TOTAL',
                        alignment: 'right',
                        bold: true,
                        colSpan: 2
                     },
                     {},
                     {
                        table: {
                           widths: [20, '*'],
                           body: [
                              [
                                 { text: '$', alignment: 'center', bold: true },
                                 {
                                    text: me.numFormatIntl(totalFinal),
                                    bold: true,
                                    alignment: 'right'
                                 }
                              ]
                           ]
                        },
                        layout: 'noBorders',
                        margin: [0, 0, 2, 0]
                     }
                  ])
               } else {
                  tempRow.push([
                     {
                        text: 'Sin datos de egresos',
                        alignment: 'center',
                        colSpan: 3
                     },
                     {},
                     {}
                  ])
               }

               return tempRow
            }

            var filename = 'Resumen individual - Caso N° ' + me.soliBecaId
            getReporteLib().then((res) => res.generarPDF('open', dd, filename))
         })
      },
      close() {
         this.show = false
      }
   }
}
</script>
