const matriculas = [
   {
      Id: 1,
      Codigo: 17,
      Nombre:
         'COLEGIO MADRE DE LA IGLESIA (HIJO DE EMPLEADO O DE ALUMNO MEDIO- DIA)',
      Valor: 0
   },
   {
      Id: 2,
      Codigo: 18,
      Nombre: 'COLEGIO MADRE DE LA IGLESIA (HIJOS EXTERNOS MEDIO- DIA)',
      Valor: 0
   },
   {
      Id: 3,
      Codigo: 19,
      Nombre: 'COLEGIO MADRE DE LA IGLESIA (HIJO DE EMPLEADO O DE ALUMNO DIA)',
      Valor: 0
   },
   {
      Id: 4,
      Codigo: 20,
      Nombre: 'COLEGIO MADRE DE LA IGLESIA (HIJOS EXTERNOS DIA)',
      Valor: 0
   },
   {
      Id: 5,
      Codigo: 101,
      Nombre: 'LICENCIATURA EN PERIODISMO Y COMUNICACION AUDIOVISUAL',
      Valor: 162
   },
   {
      Id: 6,
      Codigo: 102,
      Nombre: 'LICENCIATURA EN ENFERMERIA',
      Valor: 152
   },
   {
      Id: 7,
      Codigo: 103,
      Nombre: 'TECNICO EN ENFERMERIA',
      Valor: 152
   },
   {
      Id: 8,
      Codigo: 104,
      Nombre:
         'LICENCIATURA EN CIENCIAS DE LA EDUCACION CON ESPECIALIDAD EN IDIOMA INGLES',
      Valor: 157
   },
   {
      Id: 9,
      Codigo: 110,
      Nombre: 'DOCTORADO EN MEDICINA',
      Valor: 257
   },
   {
      Id: 10,
      Codigo: 119,
      Nombre:
         'LICENCIATURA EN CIENCIAS DE LA EDUCACION CON ESPECIALIDAD EN EDUCACION BASICA',
      Valor: 157
   },
   {
      Id: 11,
      Codigo: 120,
      Nombre: 'PROFESORADO EN EDUCACION BASICA PARA PRIMERO Y SEGUNDO CICLO',
      Valor: 0
   },
   {
      Id: 12,
      Codigo: 127,
      Nombre: 'PROFESORADO EN EDUCACION PARVULARIA',
      Valor: 0
   },
   {
      Id: 13,
      Codigo: 128,
      Nombre: 'LICENCIATURA EN CIENCIAS RELIGIOSAS',
      Valor: 157
   },
   {
      Id: 14,
      Codigo: 129,
      Nombre:
         'LICENCIATURA EN CIENCIAS DE LA EDUCACION CON ESPECIALIDAD EN EDUCACION PARVULARIA',
      Valor: 157
   },
   {
      Id: 15,
      Codigo: 130,
      Nombre: 'LICENCIATURA EN EDUCACION INICIAL Y PARVULARIA',
      Valor: 0
   },
   {
      Id: 16,
      Codigo: 131,
      Nombre:
         'LICENCIATURA EN CIENCIAS DE LA EDUCACION ESPECIALIDAD EN MATEMATICA SEMIPRESENCIAL',
      Valor: 157
   },
   {
      Id: 17,
      Codigo: 132,
      Nombre:
         'LICENCIATURA EN CIENCIAS DE LA EDUCACION ESPECIALIDAD EN DIRECCION Y ADMINISTRACION ESCOLAR - SEMIPRESENCIAL',
      Valor: 157
   },
   {
      Id: 18,
      Codigo: 133,
      Nombre:
         'LICENCIATURA EN CIENCIAS DE LA EDUCACION ESPECIALIDAD EN EDUCACION BASICA SEMIPRESENCIAL',
      Valor: 157
   },
   {
      Id: 19,
      Codigo: 135,
      Nombre: 'LICENCIATURA EN IDIOMA INGLES (SEMIPRESENCIAL)',
      Valor: 157
   },
   {
      Id: 20,
      Codigo: 140,
      Nombre: 'PROFESORADO EN EDUCACION BASICA PARA PRIMERO Y SEGUNDO CICLOS',
      Valor: 0
   },
   {
      Id: 21,
      Codigo: 144,
      Nombre: 'PROFESORADO Y LICENCIATURA EN EDUCACION INICIAL Y PARVULARIA',
      Valor: 0
   },
   {
      Id: 22,
      Codigo: 145,
      Nombre: 'LICENCIATURA EN IDIOMA INGLES',
      Valor: 157
   },
   {
      Id: 23,
      Codigo: 160,
      Nombre: 'LICENCIATURA EN DISEÑO GRAFICO PUBLICITARIO',
      Valor: 162
   },
   {
      Id: 24,
      Codigo: 209,
      Nombre: 'INGENIERIA EN TECNOLOGIA Y PROCESAMIENTO DE ALIMENTOS',
      Valor: 167
   },
   {
      Id: 25,
      Codigo: 301,
      Nombre: 'LICENCIATURA EN CIENCIAS JURIDICAS',
      Valor: 157
   },
   {
      Id: 26,
      Codigo: 302,
      Nombre:
         'LICENCIATURA EN CIENCIAS DE LA EDUCACION ESPECIALIDAD EN EDUCACION BASICA - SEMIPRESENCIAL - CENTRO REGIONAL DE METAPAN',
      Valor: 157
   },
   {
      Id: 27,
      Codigo: 303,
      Nombre: 'LICENCIATURA EN IDIOMA INGLES - CENTRO REGIONAL DE METAPAN',
      Valor: 0
   },
   {
      Id: 28,
      Codigo: 304,
      Nombre: 'INGENIERIA INDUSTRIAL - CENTRO REGIONAL DE METAPAN',
      Valor: 0
   },
   {
      Id: 29,
      Codigo: 305,
      Nombre:
         'TECNICO EN GESTION Y DESARROLLO TURISTICO - CENTRO REGIONAL DE METAPAN',
      Valor: 0
   },
   {
      Id: 30,
      Codigo: 410,
      Nombre: 'LICENCIATURA EN SISTEMAS INFORMATICOS ADMINISTRATIVOS',
      Valor: 162
   },
   {
      Id: 31,
      Codigo: 601,
      Nombre: 'INGENIERIA INDUSTRIAL',
      Valor: 157
   },
   {
      Id: 32,
      Codigo: 602,
      Nombre: 'ARQUITECTURA',
      Valor: 162
   },
   {
      Id: 33,
      Codigo: 603,
      Nombre: 'INGENIERIA CIVIL',
      Valor: 162
   },
   {
      Id: 34,
      Codigo: 604,
      Nombre: 'INGENIERIA CIVIL SANEAMIENTO AMBIENTAL',
      Valor: 157
   },
   {
      Id: 35,
      Codigo: 605,
      Nombre: 'INGENIERIA EN SISTEMAS INFORMATICOS',
      Valor: 167
   },
   {
      Id: 36,
      Codigo: 606,
      Nombre: 'INGENIERIA AGRONOMICA',
      Valor: 152
   },
   {
      Id: 37,
      Codigo: 607,
      Nombre: 'INGENIERIA EN TELECOMUNICACIONES Y REDES',
      Valor: 167
   },
   {
      Id: 38,
      Codigo: 608,
      Nombre: 'INGENIERIA EN DESARROLLO DE SOFTWARE',
      Valor: 167
   },
   {
      Id: 39,
      Codigo: 698,
      Nombre: 'CURSO CCNA ACADEMIA DE REDES CISCO UNICAES',
      Valor: 0
   },
   {
      Id: 40,
      Codigo: 699,
      Nombre: 'CURSO CCNA ACADEMIA DE REDES CISCO UNICAES',
      Valor: 0
   },
   {
      Id: 41,
      Codigo: 801,
      Nombre: 'LICENCIATURA EN ADMINISTRACION DE EMPRESAS',
      Valor: 157
   },
   {
      Id: 42,
      Codigo: 802,
      Nombre: 'LICENCIATURA EN MERCADEO Y NEGOCIOS INTERNACIONALES',
      Valor: 162
   },
   {
      Id: 43,
      Codigo: 803,
      Nombre: 'LICENCIATURA EN GESTION Y DESARROLLO TURISTICO',
      Valor: 162
   },
   {
      Id: 44,
      Codigo: 805,
      Nombre: 'LICENCIATURA EN ADMINISTRACION DE EMPRESAS - SEMIPRESENCIAL',
      Valor: 157
   },
   {
      Id: 45,
      Codigo: 812,
      Nombre: 'LICENCIATURA EN CONTADURIA PUBLICA',
      Valor: 152
   },
   {
      Id: 46,
      Codigo: 890,
      Nombre: 'POSTGRADO EN ESTRATEGIAS PARA LA COMPETITIVIDAD',
      Valor: 0
   },
   {
      Id: 47,
      Codigo: 900,
      Nombre: 'DEPARTAMENTO DE IDIOMAS',
      Valor: 0
   },
   {
      Id: 48,
      Codigo: 901,
      Nombre: 'MAESTRIA EN DIRECCION ESTRATEGICA DE EMPRESAS',
      Valor: 232
   },
   {
      Id: 49,
      Codigo: 902,
      Nombre: 'MAESTRIA EN ASESORIA EDUCATIVA',
      Valor: 232
   },
   {
      Id: 50,
      Codigo: 903,
      Nombre: 'UNIDAD DE BIENESTAR UNIVERSITARIO',
      Valor: 0
   },
   {
      Id: 51,
      Codigo: 904,
      Nombre: 'MAESTRIA EN ATENCION INTEGRAL DE LA PRIMERA INFANCIA',
      Valor: 0
   },
   {
      Id: 52,
      Codigo: 905,
      Nombre: 'MAESTRIA EN GERENCIA Y GESTION AMBIENTAL',
      Valor: 262
   },
   {
      Id: 53,
      Codigo: 906,
      Nombre: 'MAESTRIA EN GESTION Y DESARROLLO TURISTICO',
      Valor: 262
   },
   {
      Id: 54,
      Codigo: 982,
      Nombre: 'CURSO DE FORMACION PEDAGOGICA PARA PROFESIONALES',
      Valor: 0
   },
   {
      Id: 55,
      Codigo: 999,
      Nombre: 'ADMINISTRACION',
      Valor: 0
   }
]

const matriculasCRI = [
   {
      Id: 80,
      Codigo: 608,
      Nombre: 'INGENIERÍA EN DESARROLLO DE SOFTWARE (UNICAES CRI)',
      Valor: 167
   },
   {
      Id: 65,
      Codigo: 201,
      Nombre: 'INGENIERIA EN SISTEMAS INFORMÁTICOS',
      Valor: 162
   },
   {
      Id: 79,
      Codigo: 605,
      Nombre: 'INGENIERÍA EN SISTEMAS INFORMÁTICOS - (UNICAES C.R.I.)',
      Valor: 162
   },
   {
      Id: 71,
      Codigo: 209,
      Nombre: 'INGENIERÍA EN TECNOLOGÍA Y PROCESAMIENTO DE ALIMENTOS',
      Valor: 167
   },
   {
      Id: 61,
      Codigo: 133,
      Nombre:
         'LIC. EN CIENCIAS DE LA EDUCACIÓN CON ESPECIALIDAD EN EDUCACIÓN BÁSICA (SEMIPRESCENCIAL)',
      Valor: 157
   },
   {
      Id: 58,
      Codigo: 104,
      Nombre:
         'LIC. EN CIENCIAS DE LA EDUCACIÓN CON ESPECIALIDAD EN IDIOMA INGLES - (UNICAES C.R.I.)',
      Valor: 152
   },
   {
      Id: 60,
      Codigo: 132,
      Nombre:
         'LIC.EN CIENCIAS DE LA EDUCACIÓN ESPECIALIDAD DIRECCIÓN Y ADMINISTRACIÓN ESCOLAR(UNICAES C.R.I SEMIPRESENCIAL)',
      Valor: 152
   },
   {
      Id: 82,
      Codigo: 805,
      Nombre: 'LICENCIATURA EN ADMINISTRACIÓN DE EMPRESAS (SEMI PRESENCIAL)',
      Valor: 157
   },
   {
      Id: 66,
      Codigo: 202,
      Nombre:
         'LICENCIATURA EN CIENCIAS DE LA EDUCACIÓN CON ESPECIALIDAD EN IDIOMA INGLES',
      Valor: 152
   },
   {
      Id: 59,
      Codigo: 131,
      Nombre:
         'LICENCIATURA EN CIENCIAS DE LA EDUCACIÓN ESPECIALIDAD EN MATEMÁTICA - SEMIPRESENCIAL',
      Valor: 152
   },
   {
      Id: 74,
      Codigo: 222,
      Nombre: 'LICENCIATURA EN ENFERMERÍA',
      Valor: 152
   },
   {
      Id: 56,
      Codigo: 102,
      Nombre: 'LICENCIATURA EN ENFERMERÍA (UNICAES C.R.I.)',
      Valor: 152
   },
   {
      Id: 63,
      Codigo: 145,
      Nombre: 'LICENCIATURA EN IDIOMA INGLES (UNICAES C.R.I.)',
      Valor: 157
   },
   {
      Id: 68,
      Codigo: 204,
      Nombre: 'LICENCIATURA EN MERCADEO Y NEGOCIOS INTERNACIONALES',
      Valor: 157
   },
   {
      Id: 81,
      Codigo: 802,
      Nombre:
         'LICENCIATURA EN MERCADEO Y NEGOCIOS INTERNACIONALES - (UNICAES C.R.I.)',
      Valor: 157
   },
   {
      Id: 77,
      Codigo: 292,
      Nombre: 'MAESTRÍA EN ASESORÍA EDUCATIVA',
      Valor: 232
   },
   {
      Id: 83,
      Codigo: 902,
      Nombre: 'MAESTRÍA EN ASESORÍA EDUCATIVA - (UNICAES C.R.I.)',
      Valor: 232
   },
   { Id: 75, Codigo: 223, Nombre: 'TÉCNICO EN ENFERMERÍA', Valor: 152 },
   {
      Id: 57,
      Codigo: 103,
      Nombre: 'TÉCNICO EN ENFERMERÍA (UNICAES C.R.I)',
      Valor: 152
   }
]

export default { matriculas, matriculasCRI }
