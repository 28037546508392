<template>
   <v-layout align-center>
      <v-flex>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <!-- DIALOG MENSAJE -->
         <v-dialog v-model="dialogMensaje" persistent max-width="475px">
            <v-form>
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md fluid>
                        <v-layout wrap>
                           <v-flex xs12 mt-2>
                              <div
                                 class="text-justify font-weight-medium"
                                 style="font-size: 18px"
                              >
                                 {{ mensajeDialog }}
                              </div>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -20px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        text
                        @click.native="dialogMensaje = false"
                        >Salir</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG REPORTES EXCEL -->
         <v-dialog v-model="dialogReportesExcel" persistent max-width="450">
            <v-form>
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md fluid>
                        <v-layout wrap>
                           <v-flex xs12 mt-4>
                              <span class="text-h5 font-weight-medium">
                                 Reportes de solicitudes <br />
                                 <span class="colorPrimary">
                                    Ciclo {{ selectCicloPlanesPago }}
                                 </span>
                              </span>
                           </v-flex>
                           <v-flex xs12 mt-2>
                              <v-btn
                                 color="green darken-3 white--text"
                                 block
                                 @click="reportePagosPlanPago"
                              >
                                 <v-icon left>fa-file-excel</v-icon>
                                 Totales por plan de pago
                              </v-btn>
                           </v-flex>
                           <v-flex xs12 mt-2>
                              <v-btn
                                 color="green darken-3 white--text"
                                 block
                                 @click="reportePagosDetallado"
                              >
                                 <v-icon left>fa-file-excel</v-icon>
                                 Totales detallado por cuotas
                              </v-btn>
                           </v-flex>
                           <v-flex xs12>
                              <v-switch
                                 v-model="aprobados"
                                 style="margin-left: 35px"
                                 color="green darken-3"
                                 label="Mostrar solo plan de pago aprobados"
                              ></v-switch>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -20px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        text
                        @click.native="dialogReportesExcel = false"
                        >Salir</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG REPORTES INDIVIDUAL -->
         <v-dialog
            v-model="dialogReportesIndividual"
            persistent
            max-width="400px"
         >
            <v-form>
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md fluid>
                        <v-layout wrap>
                           <v-flex xs12
                              ><span class="text-h5 font-weight-medium">
                                 Reportes
                              </span>
                           </v-flex>
                           <v-flex xs12 mt-2>
                              <v-btn
                                 color="grey lighten-3"
                                 block
                                 @click="
                                    imprimirSolicitud(
                                       $vuetify.breakpoint.smAndUp
                                          ? 'open'
                                          : 'save'
                                    )
                                 "
                              >
                                 Solicitud de plan
                              </v-btn>
                           </v-flex>
                           <v-flex xs12 mt-2>
                              <v-btn
                                 color="primary"
                                 block
                                 @click="
                                    imprimirPlanPago(
                                       $vuetify.breakpoint.smAndUp
                                          ? 'open'
                                          : 'save'
                                    )
                                 "
                              >
                                 Plan de pago
                              </v-btn>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -20px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        text
                        @click.native="dialogReportesIndividual = false"
                        >Salir</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG CONSULTA NOTAS -->
         <v-dialog v-model="dialogNotas" eager max-width="1200px">
            <v-card>
               <v-card-text class="text--primary" style="margin-top: -20px">
                  <v-container grid-list-md>
                     <v-layout wrap>
                        <RecordAlu
                           :carnet="carnet"
                           :all="false"
                           :show-dialog="dialogNotas"
                        />
                     </v-layout>
                  </v-container>
               </v-card-text>
               <v-card-actions style="margin-top: -30px; padding-bottom: 20px">
                  <v-spacer></v-spacer>
                  <v-btn
                     color="primary"
                     dark
                     outlined
                     min-width="150"
                     @click.native="dialogNotas = false"
                     >Salir</v-btn
                  >
                  <v-spacer></v-spacer>
               </v-card-actions>
            </v-card>
         </v-dialog>

         <!-- DIALOG ELIMINAR FORMULARIO -->
         <v-dialog v-model="dialogEliminar" persistent max-width="600px">
            <v-form ref="formEliminar">
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium"
                                 >Remover formulario</span
                              >
                           </v-flex>
                           <v-flex xs12>
                              <span
                                 class="font-weight-regular"
                                 style="font-size: 20px"
                                 >¿Esta seguro que desea remover el
                                 formulario?</span
                              >

                              <v-layout wrap style="margin-top: 10px">
                                 <v-flex xs12 sm6>
                                    <div style="font-size: 18px">
                                       N° de caso:
                                    </div>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="border-radius: 10px"
                                    >
                                       <v-card-text>
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{ numeroCaso }}</span
                                          >
                                       </v-card-text>
                                    </v-card>
                                 </v-flex>
                                 <v-flex xs12 sm6>
                                    <div style="font-size: 18px">Carnet:</div>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="border-radius: 10px"
                                    >
                                       <v-card-text>
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{ carnet }}</span
                                          >
                                       </v-card-text>
                                    </v-card>
                                 </v-flex>
                                 <v-flex xs12 sm6>
                                    <div style="font-size: 18px">
                                       Numero talonario:
                                    </div>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="border-radius: 10px"
                                    >
                                       <v-card-text>
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{ nTalonario }}</span
                                          >
                                       </v-card-text>
                                    </v-card>
                                 </v-flex>
                                 <v-flex xs12 sm6>
                                    <div style="font-size: 18px">
                                       Fecha de solicitud:
                                    </div>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="border-radius: 10px"
                                    >
                                       <v-card-text>
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{ fechaSolicitud }}</span
                                          >
                                       </v-card-text>
                                    </v-card>
                                 </v-flex>
                              </v-layout>
                           </v-flex>
                           <v-flex xs12 text-center>
                              <v-icon
                                 color="primary"
                                 size="70"
                                 class="mt-2 mb-2"
                              >
                                 fa-trash-alt
                              </v-icon>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="margin-top: -30px; padding-bottom: 20px"
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        dark
                        outlined
                        min-width="150"
                        @click.native="removerFormulario"
                        >Si</v-btn
                     >
                     <v-btn
                        color="primary"
                        dark
                        min-width="150"
                        @click.native="close"
                        >No</v-btn
                     >
                     <v-spacer></v-spacer>
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG UPDATE FECHA CUOTA PLAN PAGO-->
         <v-dialog v-model="dialogUpdateCuotaPlan" persistent max-width="450px">
            <v-form>
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md fluid>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5">
                                 Actualizar fecha: Cuota
                                 {{
                                    numeroCuotaPlan +
                                    ' - ' +
                                    periodoLectivoPlanCuota
                                 }}
                              </span>
                           </v-flex>
                           <v-flex xs12>
                              <CustomDatePicker
                                 :min="minDatePlanPago"
                                 :date="fechaPagoPlanCuota"
                                 label="Fecha"
                                 @updateFecha="fechaPagoPlanCuota = $event"
                              />
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -20px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn color="primary" text @click.native="closePlanCuota"
                        >Salir</v-btn
                     >
                     <v-btn
                        color="primary white--text"
                        :disabled="!validSolicitud"
                        @click.native="savePlanCuota"
                        >Guardar</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>
         <!-- DIALOGO DE ACTUALIZACION DE DETALLES DE PLANES DE PAGOS -->
         <v-dialog v-model="dialogActualizar" persistent max-width="600">
            <v-card>
               <v-card-text class="text--primary" style="margin-top: 20px">
                  <v-container grid-list-md>
                     <v-layout wrap>
                        <v-flex xs12>
                           <span class="text-h5 font-weight-medium">
                              Regenerar
                           </span>
                        </v-flex>
                        <v-flex xs12 justify-center>
                           <span
                              class="font-weight-regular"
                              style="font-size: 20px"
                           >
                              ¿Esta seguro de regenerar los detalles del plan de
                              pago?
                           </span>
                           <v-layout wrap style="margin-top: 30px">
                              <v-flex xs12 sm12>
                                 <v-card
                                    flat
                                    color="red lighten-5"
                                    class="text-center elevation-1"
                                    style="border-radius: 10px"
                                 >
                                    <v-card-text>
                                       <span
                                          class="red--text text--darken-2 text-h6"
                                       >
                                          <v-text class="font-weight-bold"
                                             >IMPORTANTE:
                                          </v-text>
                                          Este botón permite corregir los
                                          detalles de planes de pagos mal
                                          generados, (no actualiza cuotas).
                                       </span>
                                    </v-card-text>
                                 </v-card>
                              </v-flex>
                           </v-layout>
                        </v-flex>
                     </v-layout>
                  </v-container>
               </v-card-text>

               <v-card-actions style="margin-top: 5px; padding-bottom: 20px">
                  <v-spacer></v-spacer>
                  <v-btn
                     color="primary"
                     dark
                     width="150"
                     outlined
                     @click="actualizarDetalles"
                  >
                     Si
                  </v-btn>
                  <v-btn
                     color="primary"
                     dark
                     width="150"
                     @click="dialogActualizar = false"
                  >
                     No
                  </v-btn>
                  <v-spacer></v-spacer>
               </v-card-actions>
            </v-card>
         </v-dialog>

         <v-dialog
            v-model="dialogActualizarPlanPago"
            persistent
            max-width="600"
         >
            <v-card>
               <v-card-text class="text--primary" style="margin-top: 20px">
                  <v-container grid-list-md>
                     <v-layout wrap>
                        <v-flex xs12>
                           <span class="text-h5 font-weight-medium">
                              Actualizar
                           </span>
                        </v-flex>
                        <v-flex xs12 justify-center>
                           <span
                              class="font-weight-regular"
                              style="font-size: 20px"
                           >
                              ¿Esta seguro de actualizar los detalles del plan
                              de pago?
                           </span>
                           <v-layout wrap style="margin-top: 30px">
                              <v-flex xs12 sm12>
                                 <v-card
                                    flat
                                    color="red lighten-5"
                                    class="text-center elevation-1"
                                    style="border-radius: 10px"
                                 >
                                    <v-card-text>
                                       <span
                                          class="red--text text--darken-2 text-h6"
                                       >
                                          <v-text class="font-weight-bold"
                                             >IMPORTANTE:
                                          </v-text>
                                          Este proceso actualizará todos los
                                          detalles de los planes de pago.
                                       </span>
                                    </v-card-text>
                                 </v-card>
                              </v-flex>
                           </v-layout>
                        </v-flex>
                     </v-layout>
                  </v-container>
               </v-card-text>

               <v-card-actions style="margin-top: 5px; padding-bottom: 20px">
                  <v-spacer></v-spacer>
                  <v-btn
                     color="primary"
                     dark
                     width="150"
                     outlined
                     @click="actualizarPlanPago"
                  >
                     Si
                  </v-btn>
                  <v-btn
                     color="primary"
                     dark
                     width="150"
                     @click="dialogActualizarPlanPago = false"
                  >
                     No
                  </v-btn>
                  <v-spacer></v-spacer>
               </v-card-actions>
            </v-card>
         </v-dialog>
         <v-dialog v-model="dialogActualizarLoading" persistent width="300">
            <v-card color="primary" dark class="pt-5 pb-2">
               <v-card-text style="color: white">
                  Procesando...
                  <v-progress-linear
                     indeterminate
                     color="white"
                     class="mb-0"
                  ></v-progress-linear>
               </v-card-text>
            </v-card>
         </v-dialog>

         <v-dialog
            v-model="dialogActualizarCuotasPlanPago"
            persistent
            width="300"
         >
            <v-card color="primary" dark class="pt-5 pb-2">
               <v-card-text style="color: white">
                  Actualizando Correctamente
                  <v-progress-linear
                     indeterminate
                     color="white"
                     class="mb-0"
                  ></v-progress-linear>
               </v-card-text>
            </v-card>
         </v-dialog>

         <!-- CONTAINER VER FORMULARIO Y PLAN DE PAGO -->
         <v-container v-if="verNuevo" persistent max-width="850px">
            <v-form ref="formPlan" v-model="validSolicitud" lazy-validation>
               <v-card elevation="0">
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md fluid>
                        <v-layout wrap>
                           <v-flex xs6>
                              <span class="text-h5 font-weight-medium">
                                 Acuerdo de plan de pago
                              </span>
                           </v-flex>
                           <v-flex xs6 class="text-right">
                              <v-btn
                                 class="mr-4"
                                 color="grey lighten-3"
                                 @click="dialogNotas = true"
                                 >Consultar notas
                                 <v-icon color="grey darken-2" right
                                    >fas fa-graduation-cap</v-icon
                                 ></v-btn
                              >
                              <v-btn
                                 class="grey lighten-3"
                                 @click="dialogReportesIndividual = true"
                                 >Reportes
                                 <v-icon color="grey darken-2" right
                                    >fa-print</v-icon
                                 ></v-btn
                              >
                           </v-flex>
                           <v-flex xs12>
                              <span
                                 class="font-weight-medium"
                                 style="font-size: 20px"
                                 >Información del alumno.</span
                              >
                           </v-flex>

                           <v-flex xs12 sm3>
                              <v-text-field
                                 v-model="numeroCaso"
                                 label="N° de caso"
                                 color="primary"
                                 readonly
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm3>
                              <v-text-field
                                 v-model="carnet"
                                 label="Carnet"
                                 color="primary"
                                 readonly
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm3>
                              <v-text-field
                                 v-model="nTalonario"
                                 label="Número de talonario"
                                 color="primary"
                                 readonly
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm3>
                              <v-autocomplete
                                 v-model="selectAsesor"
                                 :items="cmbAsesores"
                                 :readonly="oidcUser.nivelasesor == 1"
                                 item-value="id"
                                 item-text="userClaims[0].claimValue"
                                 label="Asesor"
                                 color="primary"
                              ></v-autocomplete>
                           </v-flex>
                           <v-flex xs12>
                              <v-layout wrap>
                                 <v-flex xs12 sm4>
                                    <v-text-field
                                       v-model="nombres"
                                       label="Nombres"
                                       color="primary"
                                       readonly
                                    ></v-text-field>
                                 </v-flex>
                                 <v-flex xs12 sm4>
                                    <v-text-field
                                       v-model="apellidos"
                                       label="Apellidos"
                                       color="primary"
                                       readonly
                                    ></v-text-field>
                                 </v-flex>
                                 <v-flex xs12 sm4>
                                    <CustomDatePicker
                                       :date="fechaSolicitud"
                                       label="Fecha"
                                       readonly
                                       @updateFecha="fechaSolicitud = $event"
                                    />
                                 </v-flex>
                                 <v-flex xs12 sm6>
                                    <v-autocomplete
                                       v-model="selectCarreraId"
                                       :items="cmbCarrera"
                                       item-text="nombre"
                                       item-value="carreraUniversitariaId"
                                       label="Carrera"
                                       color="primary"
                                       readonly
                                    ></v-autocomplete>
                                 </v-flex>
                                 <v-flex xs12 sm3>
                                    <v-text-field
                                       v-model="email"
                                       label="Correo electrónico"
                                       prepend-icon="fa-at"
                                       color="primary"
                                       readonly
                                    ></v-text-field>
                                 </v-flex>
                                 <v-flex xs12 sm3>
                                    <v-text-field
                                       v-model="telefono"
                                       v-mask="'####-####'"
                                       prepend-icon="fab fa-whatsapp"
                                       label="Telefono"
                                       color="primary"
                                       readonly
                                    ></v-text-field>
                                 </v-flex>
                                 <v-flex xs12>
                                    <v-textarea
                                       v-model="comentarioAlumno"
                                       prepend-icon="fa-comment-dots"
                                       label="Justificación de alumno"
                                       color="primary"
                                       rows="5"
                                       outlined
                                       counter
                                       readonly
                                    ></v-textarea>
                                 </v-flex>
                                 <v-flex
                                    v-if="rutaArchivoAdjunto.trim() != ''"
                                    xs12
                                    class="text-subtitle-1"
                                    style="
                                       margin-top: -20px;
                                       margin-bottom: 20px;
                                    "
                                 >
                                    <span
                                       class="text-subtitle-1 font-weight-medium mr-2"
                                       >Archivo adjunto:</span
                                    >
                                    <v-chip
                                       color="primary"
                                       @click="descargarAdjunto"
                                    >
                                       <v-avatar left>
                                          <v-icon dark
                                             >fa-arrow-alt-circle-down</v-icon
                                          >
                                       </v-avatar>
                                       {{
                                          rutaArchivoAdjunto.match(
                                             /[^\\]*(?=[.][a-zA-Z]+$)/
                                          ) + '.pdf'
                                       }}</v-chip
                                    >
                                 </v-flex>
                                 <v-flex xs12>
                                    <v-textarea
                                       v-model="comentarioAsesor"
                                       :readonly="selectEstadoCaso == 3"
                                       prepend-icon="fa-comment-dots"
                                       label="Comentario de asesor"
                                       color="primary"
                                       rows="5"
                                       outlined
                                       counter
                                    ></v-textarea>
                                 </v-flex>
                              </v-layout>
                           </v-flex>
                           <v-flex xs12 sm12>
                              <v-card :elevation="2">
                                 <v-toolbar color="primary" dark>
                                    <v-toolbar-title
                                       >Plan de pago
                                    </v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                       v-if="
                                          checkModificarAsesor() &&
                                          selectEstadoCaso === 2
                                       "
                                       text
                                       dark
                                       @click="dialogActualizar = true"
                                    >
                                       Regenerar
                                       <v-icon right color="white">
                                          fas fa-sync-alt
                                       </v-icon>
                                    </v-btn>
                                    <v-btn
                                       text
                                       dark
                                       @click="actualizarPlanPagoIndividual"
                                    >
                                       Actualizar
                                       <v-icon right color="white">
                                          fas fa-redo-alt
                                       </v-icon>
                                    </v-btn>
                                 </v-toolbar>

                                 <!-- DATATABLE CUOTAS EN MORA DE PLAN PAGO -->
                                 <v-data-table
                                    :headers="headersPlanPago"
                                    :items="planPagoMoras"
                                    hide-default-footer
                                    :items-per-page="-1"
                                    :footer-props="{
                                       itemsPerPageOptions: [-1],
                                       itemsPerPageText: 'Filas por página:'
                                    }"
                                    class="elevation-0"
                                    ><!-- Si no hay datos, mostrar boton para listar de nuevo -->
                                    <template slot="no-data">
                                       <div v-if="planPagoMoras.length == 0">
                                          <v-container>
                                             <v-layout wrap align-center>
                                                <v-flex
                                                   xs12
                                                   class="text-center"
                                                >
                                                   <v-btn
                                                      color="primary"
                                                      @click="reset"
                                                      >No hay Cuotas
                                                      pendientes</v-btn
                                                   >
                                                </v-flex>
                                             </v-layout>
                                          </v-container>
                                       </div>
                                    </template>
                                    <template #item.moraExonerada="{ item }">
                                       $
                                       {{ numFormatIntl(item.moraExonerada) }}
                                    </template>
                                    <template #item.opciones="{ item }">
                                       <v-btn
                                          text
                                          small
                                          fab
                                          :disabled="selectEstadoCaso == 3"
                                          color="amber darken-3"
                                          @click="editPlanPagoCuota(item)"
                                       >
                                          <v-icon>fa-pen</v-icon>
                                       </v-btn>
                                    </template>
                                    <template #item.estado="{ item }">
                                       <v-chip
                                          :color="getColorEstado(item)"
                                          dark
                                       >
                                          <span>{{
                                             item.facturado
                                                ? 'Cancelado'
                                                : getStatus(item)
                                          }}</span>
                                       </v-chip>
                                    </template>
                                    <template #item.monto="{ item }">
                                       $ {{ numFormatIntl(item.monto) }}
                                    </template>

                                    <template #body.append="{}">
                                       <!-- Linea de totales -->
                                       <tr v-if="$vuetify.breakpoint.smAndUp">
                                          <td
                                             colspan="4"
                                             align="right"
                                             class="text-body-1 font-weight-medium"
                                             style="padding-top: 4px"
                                          >
                                             TOTALES
                                          </td>
                                          <td
                                             class="text-body-1 font-weight-medium"
                                          >
                                             <v-text-field
                                                v-model="totalMoraExonerada"
                                                readonly
                                                reverse
                                                dense
                                                hide-details
                                                style="
                                                   margin-top: 20px;
                                                   margin-bottom: 10px;
                                                "
                                             ></v-text-field>
                                          </td>
                                          <td
                                             class="text-body-1 font-weight-medium"
                                          >
                                             <v-text-field
                                                v-model="totalPlanTabla"
                                                readonly
                                                reverse
                                                dense
                                                hide-details
                                                style="
                                                   margin-top: 20px;
                                                   margin-bottom: 10px;
                                                "
                                             ></v-text-field>
                                          </td>
                                          <td></td>
                                       </tr>

                                       <!-- VISTA EN MODO EXTRA SMALL -->
                                       <tr v-if="$vuetify.breakpoint.xsOnly">
                                          <td
                                             :colspan="headers.length"
                                             class="text-body-1 font-weight-medium"
                                          >
                                             <span class="text-h6 colorPrimary">
                                                TOTALES
                                             </span>
                                             <v-text-field
                                                v-model="totalMoraExonerada"
                                                readonly
                                                label="Total mora exonerada"
                                                reverse
                                             ></v-text-field>
                                             <v-text-field
                                                v-model="totalPlanTabla"
                                                readonly
                                                label="Total monto"
                                                reverse
                                             ></v-text-field>
                                          </td>
                                       </tr>
                                    </template>
                                 </v-data-table>
                              </v-card>
                           </v-flex>
                           <v-flex xs12 sm6 class="mt-4">
                              <v-autocomplete
                                 v-model="selectEstadoCaso"
                                 :items="cmbEstadoCaso"
                                 :readonly="
                                    selectEstadoCaso == 3 &&
                                    oidcUser.nivelasesor == 1
                                 "
                                 color="primary"
                                 item-text="nombre"
                                 item-value="estadoCasoId"
                                 label="Resolución"
                                 placeholder="Seleccionar..."
                              >
                              </v-autocomplete>
                           </v-flex>
                           <v-flex xs12 sm6 class="mt-4">
                              <v-autocomplete
                                 v-model="selectTipoCaso"
                                 :readonly="
                                    selectEstadoCaso == 3 ||
                                    oidcUser.nivelasesor == 1
                                 "
                                 :items="cmbTipoCaso"
                                 color="primary"
                                 item-text="nombre"
                                 item-value="tipoCasoId"
                                 label="Tipo Caso"
                                 placeholder="Seleccionar..."
                              >
                              </v-autocomplete>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>
                  <v-card-actions
                     style="
                        margin-top: -20px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn color="primary" text @click.native="close"
                        >Salir</v-btn
                     >
                     <v-btn color="primary white--text" @click.native="save"
                        >Guardar</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-container>

         <!-- LISTA DE SOLICITUDES -->
         <v-card v-if="!verNuevo" elevation="0" outlined max-width="5000">
            <v-toolbar color="primary" dark>
               <v-toolbar-title style="font-weight: 500">
                  Solicitudes de planes de pagos
               </v-toolbar-title>
               <div class="mt-6 ml-3" style="width: 150px">
                  <v-autocomplete
                     v-model="selectCicloPlanesPago"
                     label="Ciclo"
                     :items="cmbCiclosPlanesPago"
                  >
                  </v-autocomplete>
               </div>
               <v-spacer></v-spacer>
               <v-text-field
                  v-if="!showFilterAsesor"
                  ref="txtBusqueda"
                  v-model="searchComputed"
                  append-icon="fa-search"
                  class="mt-6"
                  color="white"
                  label="Busqueda"
                  single-line
                  hide-deatails
               ></v-text-field>
               <v-autocomplete
                  v-if="
                     (oidcUser.nivelasesor > 1 || oidcUser.role == 'admin') &&
                     showFilterAsesor
                  "
                  v-model="selectAsesorFilter"
                  class="mt-3"
                  :items="cmbAsesores"
                  item-value="id"
                  item-text="userClaims[0].claimValue"
                  placeholder="Filtrar por asesor"
                  color="white"
               ></v-autocomplete>
               <v-spacer></v-spacer>
               <v-btn
                  v-if="oidcUser.nivelasesor > 1 || oidcUser.role == 'admin'"
                  text
                  dark
                  @click="dialogActualizarPlanPago = true"
               >
                  Actualizar <v-icon right>fas fa-redo-alt</v-icon>
               </v-btn>
               <v-btn
                  v-if="oidcUser.nivelasesor > 1 || oidcUser.role == 'admin'"
                  text
                  dark
                  @click="dialogReportesExcel = true"
               >
                  Reportes <v-icon right>fa-print</v-icon>
               </v-btn>
            </v-toolbar>
            <!-- DATATABLE SOLICITUDES -->
            <v-data-table
               :headers="headers"
               :items="filteredSolicitudes"
               :loading="loadingSolicitudes"
               fixed-header
               :sort-by.sync="soliSortBy"
               :sort-desc.sync="soliSortDesc"
               :page.sync="soliPage"
               :items-per-page.sync="soliItemsPerPage"
               height="64vh"
               no-data-text="No se encontraron solicitudes de plan de pago para el ciclo seleccionado."
               loading-text="Listando solicitudes de plan de pago, por favor espere..."
               :search="search"
               :footer-props="footerByUser(oidcUser)"
               class="elevation-0 mt-3"
            >
               <!-- Filtros de busqueda -->
               <template #footer="{}">
                  <tr>
                     <td :colspan="headers.length">
                        <v-layout align-center justify-start fill-height>
                           <div class="mx-4">
                              <span class="text-body-1 font-weight-medium">
                                 Buscar por
                              </span>
                           </div>
                           <div style="margin-top: 5px">
                              <v-radio-group
                                 v-model="radiosBusqueda"
                                 row
                                 @change="cambiaRadios"
                              >
                                 <v-radio
                                    label="N° Caso"
                                    color="primary"
                                    value="r-ncaso"
                                 ></v-radio>
                                 <v-radio
                                    label="Carnet"
                                    color="primary"
                                    value="r-carnet"
                                 ></v-radio>
                                 <v-radio
                                    label="Nombres"
                                    color="primary"
                                    value="r-nombres"
                                 ></v-radio>
                                 <v-radio
                                    label="Apellidos"
                                    color="primary"
                                    value="r-apellidos"
                                 ></v-radio>
                                 <v-radio
                                    label="Fecha Solicitud"
                                    color="primary"
                                    value="r-fecha"
                                 ></v-radio>
                                 <v-radio
                                    label="Resolución"
                                    color="primary"
                                    value="r-resolucion"
                                 ></v-radio>
                                 <v-radio
                                    v-if="
                                       oidcUser.nivelasesor > 1 ||
                                       oidcUser.role == 'admin'
                                    "
                                    label="Asesor"
                                    color="primary"
                                    value="r-asesor"
                                 ></v-radio>
                                 <v-radio
                                    label="Estado"
                                    color="primary"
                                    value="r-estado"
                                 ></v-radio>
                              </v-radio-group>
                           </div>
                        </v-layout>
                     </td>
                  </tr>
               </template>
               <template #item.estadoCaso="{ item }">
                  <v-chip :color="getColorEstadoSoli(item)" dark>
                     <span>{{ item.estadoCaso }}</span>
                  </v-chip>
               </template>

               <template #item.progreso="{ item }">
                  <v-chip :color="getColorProgreso(item)" dark>
                     <span>{{ item.progreso }}</span>
                  </v-chip>
               </template>

               <template #item.asesorId="{ item }">
                  {{ getNombreAsesor(item) }}
               </template>
               <template #item.opciones="{ item }">
                  <v-tooltip bottom color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="amber darken-3"
                           v-on="on"
                           @click="mostrarPlanPago(item)"
                        >
                           <v-icon>fa-pen</v-icon>
                        </v-btn>
                     </template>
                     <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           v-if="checkRangoAlto()"
                           text
                           small
                           fab
                           color="primary"
                           v-on="on"
                           @click="deleteItem(item)"
                        >
                           <v-icon>fa-trash-alt</v-icon>
                        </v-btn>
                     </template>
                     <span>Eliminar</span>
                  </v-tooltip>
               </template>
            </v-data-table>
         </v-card>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import debounce from 'lodash/debounce'
import { mask } from 'vue-the-mask'
import { authComputed } from '../helpers'
import formatters from '../mixins/formattersLib'
import Carreras from '../mixins/carreras'
const RecordAlu = () => import('./RecordAlu')
const CustomDatePicker = () => import('./custom/CustomDatePicker')
const getReporteLib = () => import('../mixins/reporteLib')
import XLSX from 'xlsx'

export default {
   name: 'SolicitudesPlanPagos',
   components: {
      CustomDatePicker,
      RecordAlu
   },
   directives: {
      mask
   },
   mixins: [formatters],
   data: (vm) => ({
      dialogNotas: false,
      dialogEliminar: false,
      dialogUpdateCuotaPlan: false,
      dialogMensaje: false,
      dialogReportesExcel: false,
      dialogReportesIndividual: false,
      loadingSolicitudes: false,
      showFilterAsesor: false,
      totalPlan: 0,
      aprobados: false,
      fechaInicioAbsolucion: null,
      fechaFinAbsolucion: null,
      cicloExonerado: null,
      mensajeDialog: '',
      planPagoMoras: [],
      planPagoMorasOrig: [],
      editedIndex: -1,
      dialogActualizarLoading: false,
      dialogActualizarCuotasPlanPago: false,
      headers: [
         {
            text: 'N° Caso',
            value: 'numeroCaso',
            width: 120,
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Carnet',
            value: 'carnet',
            width: 140,
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Talonario',
            value: 'numeroTalonario',
            sortable: false,
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Nombres',
            value: 'nombres',
            class: 'titulo'
         },
         {
            text: 'Apellidos',
            value: 'apellidos',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Carrera',
            width: 225,
            value: 'carreraUniversitaria',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Fecha de Solicitud',
            value: 'createOn',
            align: 'center',
            class: 'titulo',
            sort: (a, b) => {
               var date1 = new Date(
                  a.split(' ')[0].replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$2/$1')
               )
               var date2 = new Date(
                  b.split(' ')[0].replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$2/$1')
               )

               return date1 < date2 ? -1 : 1
            }
         },
         {
            text: 'Resolución',
            value: 'estadoCaso',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Tipo',
            value: 'tipoCaso',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Asesor',
            value: 'asesorId',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Estado',
            value: 'progreso',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Opciones',
            value: 'opciones',
            width: 140,
            sortable: false,
            align: 'right',
            class: 'titulo'
         }
      ],
      carnet: '',
      headersPlanPago: [
         {
            text: 'N° Cuota',
            value: 'cuotaNumero',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Ciclo',
            value: 'periodoLectivo',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Fecha Vencimiento',
            value: 'fechaVencimiento',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Pagaré / Fecha',
            value: 'fechaPago',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Recargo exonerado',
            value: 'moraExonerada',
            class: 'titulo',
            align: 'right'
         },
         {
            text: 'Monto',
            value: 'monto',
            class: 'titulo',
            align: 'right'
         },
         {
            text: 'Estado',
            value: 'estado',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Opciones',
            value: 'opciones',
            sortable: false,
            align: 'right',
            class: 'titulo'
         }
      ],

      // Variables de formularios
      search: '',
      result: [],
      solicitudes: [],
      soliSortBy: [],
      soliSortDesc: [],
      soliPage: 1,
      soliItemsPerPage: 25,
      verNuevo: false,
      validSolicitud: true,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',

      // Propiedades formulario
      cicloActual: null,
      cmbCiclosPlanesPago: [],
      selectCicloPlanesPago: '',
      numeroCaso: '',
      nombres: '',
      apellidos: '',
      radiosBusqueda: 'r-nombres',
      selectCarreraId: '',
      cmbCarrera: [],
      fechaSolicitud: vm.toISOLocal(new Date()).substr(0, 10),
      email: '',
      telefono: '',
      nTalonario: '',
      selectAsesor: '',
      selectAsesorFilter: '',
      cmbAsesores: [],
      cmbUsuarios: [],
      rutaArchivoAdjunto: '',
      comentarioAsesor: '',
      comentarioAlumno: '',
      selectEstadoCaso: '',
      cmbEstadoCaso: '',
      selectTipoCaso: '',
      cmbTipoCaso: '',
      cuotaPlan: [],
      numeroCuotaPlan: '',
      periodoLectivoPlanCuota: '',
      npePlanCuota: '',
      fechaPagoPlanCuota: '',
      montoPlanCuota: '',
      fechaVencimientoPlanCuota: '',
      tempAsesorName: '',
      tempMontoMatricula: '',
      dialogActualizar: false,
      dialogActualizarPlanPago: false
   }),
   computed: {
      ...authComputed,
      minDatePlanPago() {
         if (
            this.planPagoMoras.some(
               (obj) => obj.periodoLectivo != this.cicloActual
            ) &&
            this.cuotaPlan != [] &&
            this.cuotaPlan.cuotaNumero == 1 &&
            this.cuotaPlan.periodoLectivo == this.cicloActual
         ) {
            // Fecha debe ser mayor o igual que la penultima fecha
            return this.parseDate(
               this.planPagoMoras[this.planPagoMoras.length - 2].fechaPago
            )
         } else {
            return this.toISOLocal(new Date()).substr(0, 10)
         }
      },
      totalMoraExonerada() {
         var res = 0
         for (let i = 0; i < this.planPagoMoras.length; i++) {
            const element = this.planPagoMoras[i]
            res = Number(res) + Number(element.moraExonerada)
         }
         return '$ ' + this.numFormatIntl(res)
      },
      // Calculo de totales de datatable plan de pago
      totalPlanTabla() {
         var res = 0
         for (let i = 0; i < this.planPagoMoras.length; i++) {
            const element = this.planPagoMoras[i]
            res = Number(res) + Number(element.monto)
         }
         return '$ ' + this.numFormatIntl(res)
      },
      // Titulo de dialog
      formTitle() {
         return this.editedIndex === -1
            ? 'Nueva solicitud'
            : 'Ver / Editar solicitud'
      },
      filteredSolicitudes() {
         switch (this.radiosBusqueda) {
            case 'r-nombres':
               return this.solicitudes.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.nombres)
                  )
               })
               break
            case 'r-carnet':
               return this.solicitudes.filter((i) => {
                  return (
                     !this.search || new RegExp(this.search, 'i').test(i.carnet)
                  )
               })
               break
            case 'r-apellidos':
               return this.solicitudes.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.apellidos)
                  )
               })
               break
            case 'r-fecha':
               return this.solicitudes.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.fechaSolicitud)
                  )
               })
               break
            case 'r-ncaso':
               return this.solicitudes.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.numeroCaso)
                  )
               })
               break
            case 'r-resolucion':
               return this.solicitudes.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.estadoCaso)
                  )
               })
               break
            case 'r-estado':
               return this.solicitudes.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.progreso)
                  )
               })
               break
            case 'r-asesor':
               return this.solicitudes.filter((i) => {
                  return (
                     !this.selectAsesorFilter ||
                     this.selectAsesorFilter == i.asesorId
                  )
               })
               break
            default:
               return this.solicitudes
         }
      },
      searchComputed: {
         get() {
            return this.search
         },
         set: debounce(function (newVal) {
            this.search = newVal.trim()
         }, 500)
      }
   },
   watch: {
      selectEstadoCaso(val, oldVal) {
         if (!this.checkRangoAlto() && val == 3) {
            this.snackText = 'No esta autorizado para aprobar un plan de pago.'
            this.snackColor = 'snackWarning'
            this.snackbar = true
            setTimeout(() => {
               this.selectEstadoCaso = oldVal
            }, 200)
         }
      },
      selectCicloPlanesPago() {
         this.listarSolicitudes()
      },
      planPagoMoras(val) {
         if (val.length > 0) {
            // Calcular mora exonerada
            val.forEach((el) => {
               if (
                  (this.parseDate(el.fechaVencimiento) >=
                     this.fechaInicioAbsolucion &&
                     this.parseDate(el.fechaVencimiento) <=
                        this.fechaFinAbsolucion) ||
                  el.periodoLectivo == this.cicloExonerado
               ) {
                  el.moraExonerada = 12
               } else {
                  el.moraExonerada = 0
               }
            })
         }
      }
   },
   created() {
      // Validando acceso al componente
      if (this.permController(1, 'ver')) {
         this.listarComboboxes()
         this.carrerasJson =
            process.env.VUE_APP_SEDE == 'SA'
               ? Carreras.matriculas
               : Carreras.matriculasCRI
      } else {
         this.$router.push({ name: 'inicio' })
      }
   },
   methods: {
      getModifyOn() {
         return this.result.modifyOn === null ? '-' : this.result.modifyOn
      },
      footerByUser(user) {
         if (user.nivelasesor > 1 || user.role == 'admin') {
            return {
               itemsPerPageOptions: [25, 50, 75],
               itemsPerPageText: 'Filas por página:'
            }
         } else {
            return {
               itemsPerPageOptions: [10, 25],
               itemsPerPageText: 'Filas por página:'
            }
         }
      },
      cambiaRadios() {
         if (this.radiosBusqueda != 'r-asesor') {
            this.showFilterAsesor = false
            this.selectAsesorFilter = ''
            setTimeout(() => {
               this.focusBusqueda()
            }, 300)
         } else {
            this.showFilterAsesor = true
            this.selectAsesorFilter = ''
            this.search = ''
         }
      },
      checkRangoAlto() {
         if (this.oidcUser) {
            return Number(this.oidcUser.nivelasesor) > 1 ||
               this.oidcUser.role == 'admin'
               ? true
               : false
         } else {
            return false
         }
      },
      checkModificarAsesor() {
         if (this.oidcUser !== null) {
            return this.oidcUser.role === 'admin' ? true : false
         } else {
            return false
         }
      },

      getNombreAsesor(item) {
         if (item != undefined && this.cmbUsuarios.length > 0) {
            return this.cmbUsuarios.find((obj) => {
               return obj.id === item.asesorId
            })
               ? this.cmbUsuarios.find((obj) => {
                    return obj.id === item.asesorId
                 }).userClaims[0].claimValue
               : 'ASESOR MALCONFIGURADO'
         }
      },
      editPlanPagoCuota(item) {
         this.cuotaPlan = item
         // Obtener precios de cuota originales
         axios
            .get('api/Contrato/Cuotas?Carnet=' + this.carnet)
            .then((response) => {
               this.planPagoMorasOrig = response.data
            })
            .catch(() => {
               //console.log(error)
            })
            .finally(() => {
               this.numeroCuotaPlan = this.cuotaPlan.cuotaNumero
               this.periodoLectivoPlanCuota = this.cuotaPlan.periodoLectivo
               this.npePlanCuota = this.cuotaPlan.npe
               this.montoPlanCuota = this.cuotaPlan.monto
               this.fechaVencimientoPlanCuota = this.parseDate(
                  this.cuotaPlan.fechaVencimiento
               )
               this.fechaPagoPlanCuota = this.parseDate(
                  this.cuotaPlan.fechaPago
               )
               this.dialogUpdateCuotaPlan = true
            })
      },
      closePlanCuota() {
         this.cuotaPlan = []
         this.npePlanCuota = ''
         this.montoPlanCuota = ''
         this.fechaPagoPlanCuota = ''
         this.dialogUpdateCuotaPlan = false
      },
      savePlanCuota() {
         if (
            this.planPagoMorasOrig.find(
               (obj) => obj.npe == this.npePlanCuota
            ) !== undefined
         ) {
            // Si pone una fecha de pago mayor a la fecha de vencimiento y la fecha
            // de vencimiento de la cuota esta fuera de el rango de absolucion y
            // aun no no se le ha cargado 12 dolares, se le suman 12 dolares
            if (
               this.fechaPagoPlanCuota > this.fechaVencimientoPlanCuota &&
               (this.fechaVencimientoPlanCuota < this.fechaInicioAbsolucion ||
                  this.fechaVencimientoPlanCuota > this.fechaFinAbsolucion) &&
               this.montoPlanCuota ==
                  this.planPagoMorasOrig.find(
                     (obj) => obj.npe == this.npePlanCuota
                  ).monto &&
               this.periodoLectivoPlanCuota != this.cicloExonerado
            ) {
               this.montoPlanCuota = this.toFixedRegex(
                  Number(this.montoPlanCuota) + 12,
                  2
               )
               this.mensajeDialog =
                  'La fecha de pago es mayor a la fecha de vencimiento, recargo por PAGO EXTEMPORANEO aplicado'
               this.dialogMensaje = true
            }

            // Si pone una fecha de pago menor de la fecha de vencimiento y ya tiene
            // aplicado 12 dolares, se le restan 12 dolares
            if (
               this.fechaPagoPlanCuota <= this.fechaVencimientoPlanCuota &&
               Number(this.montoPlanCuota) !=
                  Number(
                     this.planPagoMorasOrig.find(
                        (obj) => obj.npe == this.npePlanCuota
                     ).monto
                  )
            ) {
               this.montoPlanCuota = this.toFixedRegex(
                  Number(this.montoPlanCuota) - 12,
                  2
               )

               this.mensajeDialog = 'Recargo por PAGO EXTEMPORANEO desaplicado'
               this.dialogMensaje = true
            }
         } else {
            // Validando matricula
            // Sumar 12
            if (
               this.fechaPagoPlanCuota > this.fechaVencimientoPlanCuota &&
               Number(this.montoPlanCuota) ==
                  Number(
                     this.carrerasJson.find(
                        (obj) => obj.Id == this.selectCarreraId
                     ).Valor
                  )
            ) {
               this.montoPlanCuota = this.toFixedRegex(
                  Number(this.montoPlanCuota) + 12,
                  2
               )
               this.mensajeDialog =
                  'La fecha de pago es mayor a la fecha de vencimiento, recargo por PAGO EXTEMPORANEO aplicado'
               this.dialogMensaje = true
            }

            // Restar 12
            if (
               this.fechaPagoPlanCuota <= this.fechaVencimientoPlanCuota &&
               Number(this.montoPlanCuota) !=
                  Number(
                     this.carrerasJson.find(
                        (obj) => obj.Id == this.selectCarreraId
                     ).Valor
                  )
            ) {
               this.montoPlanCuota = this.toFixedRegex(
                  Number(this.montoPlanCuota) - 12,
                  2
               )

               this.mensajeDialog = 'Recargo por PAGO EXTEMPORANEO desaplicado'
               this.dialogMensaje = true
            }
         }

         axios
            .put(
               'api/FormularioPlanPago/' +
                  this.cuotaPlan.formularioPlanPagoId +
                  '/DetallePlanPago/' +
                  this.cuotaPlan.detallePlanPagoId,
               {
                  npe: this.npePlanCuota,
                  montoPago: this.montoPlanCuota,
                  fechaPago: this.fechaPagoPlanCuota
               }
            )
            .then(() => {
               this.snackText = 'Cuota de plan actualizada exitosamente!'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
            })
            .catch((error) => {
               if (error.response.status == 304) {
                  this.snackText = 'No se pudo actualizar la cuota'
                  this.snackColor = 'snackError'
                  this.snackbar = true
               }
            })
            .finally(() => {
               // Listar plan de pago nuevamente
               axios
                  .get(
                     'api/FormularioPlanPago/' +
                        this.cuotaPlan.formularioPlanPagoId +
                        '/DetallePlanPago'
                  )
                  .then((response) => {
                     this.planPagoMoras = response.data
                  })
                  .catch(() => {
                     //console.log(error)
                  })
                  .finally(() => {
                     this.closePlanCuota()
                  })
            })
      },
      imprimirPlanPago(mode) {
         // Calculando totalPlan
         this.totalPlan = 0
         this.planPagoMoras.forEach((el) => {
            this.totalPlan = Number(this.totalPlan) + Number(el.monto)
         })
         this.totalPlan = this.numFormatIntl(this.totalPlan)

         const buildTableMora = (data) => {
            var tempRow = []
            // Encabezado
            tempRow.push([
               { text: 'Fecha de Pago', alignment: 'center', bold: true },
               { text: 'Cuota a cancelar', alignment: 'center', bold: true },
               { text: 'Valor', alignment: 'center', bold: true }
            ])

            data.forEach((el) => {
               tempRow.push([
                  { text: el.fechaPago, alignment: 'center' },
                  {
                     text: 'Cuota #' + el.cuotaNumero + ' ' + el.periodoLectivo,
                     alignment: 'center'
                  },
                  {
                     table: {
                        widths: [30, '*'],
                        body: [
                           [
                              { text: '$', alignment: 'center' },
                              {
                                 text: this.numFormatIntl(el.monto),
                                 alignment: 'right'
                              }
                           ]
                        ]
                     },
                     layout: 'noBorders',
                     margin: [0, 0, 10, 0]
                  }
               ])
            })

            // Total
            tempRow.push([
               {
                  text: 'TOTAL A CANCELAR',
                  alignment: 'right',
                  fontSize: '10',
                  bold: true,
                  colSpan: 2
               },
               {},
               {
                  table: {
                     widths: [30, '*'],
                     body: [
                        [
                           { text: '$', alignment: 'center' },
                           { text: this.totalPlan, alignment: 'right' }
                        ]
                     ]
                  },
                  layout: 'noBorders',
                  margin: [0, 0, 10, 0]
               }
            ])

            return tempRow
         }

         var dd = {
            // Tamaño de pagina
            pageSize: 'LETTER',
            pageOrientation: 'portrait',
            pageMargins: [60, 20],
            content: [
               {
                  text:
                     '\n\n\n\nSanta Ana ' +
                     this.getModifyOn().substr(0, 2) +
                     ' de ' +
                     this.obtenerMes(this.parseDate(this.getModifyOn())) +
                     ' de ' +
                     this.getModifyOn().substr(6, 4) +
                     '.\n',
                  alignment: 'right'
               },
               {
                  text:
                     '\n\nLa suscrita Directora Administrativa, en base a la solicitud del estudiante ' +
                     this.result.nombres +
                     ' ' +
                     this.result.apellidos +
                     ', con carnet No. ' +
                     this.result.carnet +
                     ', de la carrera ' +
                     this.result.carreraUniversitaria.trim() +
                     ', por este medio autoriza para que pueda realizar el' +
                     ' trámite de inscripción de materias para el Ciclo Académico ' +
                     this.result.cicloAplicacion +
                     '.\n\n ',
                  alignment: 'justify'
               },
               {
                  text: 'PLAN DE PAGO' + '\n\n',
                  alignment: 'center',
                  bold: true
               },
               {
                  text:
                     'El plan de pago mediante el cual el alumno/a ' +
                     this.result.nombres +
                     ' ' +
                     this.result.apellidos +
                     ' se compromete a cancelar la suma de $' +
                     this.totalPlan +
                     ', es el siguiente:' +
                     '\n\n '
               },
               {
                  table: {
                     widths: ['*', '*', '*'],
                     body: buildTableMora(this.planPagoMoras)
                  },
                  layout: '',
                  margin: [40, 0]
               },
               {
                  text:
                     '\n\n\n\n\n' +
                     '_________________________________\n' +
                     'Licda. Ana Michelle Espinoza\n' +
                     ' Directora Administrativa ' +
                     '\n\n',
                  alignment: 'center'
               },
               {
                  text: 'Me doy por notificado de la resolución que antecede. De no cumplir con las fechas establecidas en el plan de pago, autorizo a la Universidad a que haga efectivo el retiro del presente ciclo.',
                  alignment: 'justify'
               },
               {
                  text:
                     '\n\n\nTeléfono: ' +
                     this.result.telefono +
                     '\nCorreo: ' +
                     this.result.email +
                     '\nFecha de solicitud: ' +
                     this.result.fechaSolicitud +
                     '\nFecha de última modificación: ' +
                     this.getModifyOn()
               }
            ],
            defaultStyle: {
               fontSize: 12,
               font: 'TimesNewRoman'
            }
         }

         var filename = 'Plan de pago - ' + this.carnet + '.pdf'
         getReporteLib().then((res) => res.generarPDF(mode, dd, filename))
      },
      imprimirSolicitud(mode) {
         // Calculando totalPlan
         this.totalPlan = 0
         this.planPagoMoras.forEach((el) => {
            this.totalPlan = Number(this.totalPlan) + Number(el.monto)
         })
         this.totalPlan = this.numFormatIntl(this.totalPlan)

         const buildTableMora = (moras) => {
            var rowTemp = []

            // ENCABEZADOS
            rowTemp.push([
               {
                  text: 'Cuota',
                  alignment: 'center',
                  bold: true
               },
               { text: 'Ciclo', alignment: 'center', bold: true },
               { text: 'Monto', alignment: 'center', bold: true },
               { text: 'Fecha Pago', alignment: 'center', bold: true }
            ])

            // CUOTAS CON MORA
            moras.forEach((m) => {
               rowTemp.push([
                  { text: m.cuotaNumero, alignment: 'center' },
                  { text: m.periodoLectivo, alignment: 'center' },
                  { text: m.periodoLectivo, alignment: 'center' },
                  { text: m.fechaPago, alignment: 'center' }
               ])
            })

            // TOTAL A CANCELAR
            rowTemp.push([
               {
                  text: 'TOTAL A CANCELAR',
                  alignment: 'right',
                  bold: true,
                  colSpan: 2
               },
               {},
               {
                  table: {
                     widths: [30, '*'],
                     body: [
                        [
                           { text: '$', alignment: 'center' },
                           {
                              text: this.totalPlan,
                              alignment: 'right'
                           }
                        ]
                     ]
                  },
                  layout: 'noBorders',
                  border: [1, 1, 0, 1],
                  margin: [0, 0, 10, 0]
               },
               { text: '', border: [0, 1, 1, 1] }
            ])

            return rowTemp
         }

         var dd = {
            // Tamaño de pagina
            pageSize: 'LETTER',
            pageOrientation: 'portrait',
            pageMargins: [60, 20],
            content: [
               {
                  text: '\n\n\n' + 'SOLICITUD DE PLAN DE PAGO' + '\n\n\n\n',
                  bold: true,
                  alignment: 'center'
               },
               {
                  table: {
                     widths: ['*', '*'],
                     body: [
                        [
                           {
                              text:
                                 'Ciclo de aplicación: ' +
                                 this.result.cicloAplicacion
                           },
                           {
                              text: 'Carnet : ' + this.result.carnet,
                              alignment: 'right'
                           }
                        ]
                     ]
                  },
                  layout: 'noBorders'
               },
               {
                  text:
                     '\nNombre del alumno: ' +
                     this.result.nombres +
                     ' ' +
                     this.result.apellidos +
                     '\n' +
                     'Carrera: ' +
                     this.result.carreraUniversitaria +
                     '\n' +
                     'Telefono: ' +
                     this.result.telefono +
                     '\n' +
                     'Correo electrónico: ' +
                     this.result.email +
                     '\n\n' +
                     'Justificación o Razones: ' +
                     this.result.comentarioAlumno +
                     '\n\n\n\n',
                  alignment: 'justify'
               },
               {
                  table: {
                     widths: ['*', '*', '*', '*'],
                     body: buildTableMora(this.planPagoMoras)
                  },
                  layout: '',
                  margin: [40, 0]
               },
               {
                  text:
                     '\n\n' +
                     'Nota:\n\n 1. Adjunto mi estado de cuenta y dos hojas de retiro de la Universidad firmadas y autorizadas\n' +
                     '2. De no cumplir con las fechas establecidas en el plan de pago, acepto mi retiro de la universidad' +
                     '.\n\n',
                  alignment: 'justify'
               },
               {
                  text:
                     '\n\nAsesor: ' +
                     this.tempAsesorName +
                     '\nFecha de solicitud: ' +
                     this.result.fechaSolicitud +
                     '\nFecha de última modificación: ' +
                     this.getModifyOn(),
                  alignment: 'justify'
               }
            ],
            defaultStyle: {
               fontSize: 12,
               font: 'TimesNewRoman'
            }
         }

         var filename = 'Solicitud - ' + this.carnet + '.pdf'
         getReporteLib().then((res) => res.generarPDF(mode, dd, filename))
      },
      descargarAdjunto() {
         if (this.rutaArchivoAdjunto.trim() != '') {
            axios
               .get(
                  'api/FormularioPlanPago/ArchivoAdjunto?filepath=' +
                     this.rutaArchivoAdjunto,
                  {
                     responseType: 'arraybuffer',
                     headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/pdf'
                     }
                  }
               )
               .then((response) => {
                  this.snackText = 'Descargando archivo adjunto'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true

                  const url = window.URL.createObjectURL(
                     new Blob([response.data])
                  )
                  const link = document.createElement('a')
                  link.href = url
                  link.setAttribute(
                     'download',
                     this.rutaArchivoAdjunto.match(/[^\\]*(?=[.][a-zA-Z]+$)/) +
                        '.pdf'
                  ) //or any other extension
                  document.body.appendChild(link)
                  link.click()
               })
               .catch(() => {
                  this.snackText = 'No se pudo descargar el archivo adjunto'
                  this.snackColor = 'snackError'
                  this.snackbar = true
               })
         }
      },
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Popular el datatable
      listarSolicitudes() {
         this.loadingSolicitudes = true
         // Listar todos las solicitudes solo si es admin o nivel 2
         if (this.checkRangoAlto()) {
            axios
               .get(
                  'api/FormularioPlanPago?CicloAplicacion=' +
                     this.selectCicloPlanesPago
               )
               .then((response) => {
                  this.solicitudes = response.data
               })
               .catch(() => {
                  //console.log(error)
               })
               .finally(() => {
                  this.loadingSolicitudes = false
               })
         } else {
            axios
               .get(
                  'api/FormularioPlanPago?AsesorId=' +
                     this.oidcUser.sub +
                     '&CicloAplicacion=' +
                     this.selectCicloPlanesPago
               )
               .then((response) => {
                  this.solicitudes = response.data
               })
               .catch(() => {
                  //console.log(error)
               })
               .finally(() => {
                  this.loadingSolicitudes = false
               })
         }
      },
      listarComboboxes() {
         axios
            .get('api/Configuracion/fechaInicioAbs')
            .then((response) => {
               this.fechaInicioAbsolucion = response.data.valor
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/FormularioPlanPago/ciclosDisponibles')
            .then((response) => {
               this.cmbCiclosPlanesPago = response.data
               this.selectCicloPlanesPago =
                  response.data[response.data.length - 1]
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/Configuracion/fechaFinAbs')
            .then((response) => {
               this.fechaFinAbsolucion = response.data.valor
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/Configuracion/cicloActual')
            .then((response) => {
               this.cicloActual = response.data.valor
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/Configuracion/cicloExonerado')
            .then((response) => {
               this.cicloExonerado = response.data.valor
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/TipoCaso')
            .then((response) => {
               this.cmbTipoCaso = response.data
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/EstadoCaso?parabecas=false')
            .then((response) => {
               this.cmbEstadoCaso = response.data
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/general/CarreraUniversitaria')
            .then((response) => {
               this.cmbCarrera = response.data
            })
            .catch(() => {
               //console.log(error)
            })
         axios({
            method: 'get',
            url: 'api/UserManagement/clienteweb?soloAsesores=true',
            baseURL: process.env.VUE_APP_IDENTITY
         }).then((response) => {
            this.cmbAsesores = response.data
            //console.log(me.usuarios)
         })
         axios({
            method: 'get',
            url: 'api/UserManagement/clienteweb',
            baseURL: process.env.VUE_APP_IDENTITY
         }).then((response) => {
            this.cmbUsuarios = response.data
            //console.log(me.usuarios)
         })
      },
      // Si no cargaron bien las solicitudes en el datatable se puede resetear
      reset() {
         this.listarSolicitudes()
      },
      mostrarPlanPago(item) {
         // Trayendo informacion del formulario
         this.verNuevo = true
         this.editedIndex = 1
         axios
            .get('api/FormularioPlanPago/' + item.numeroCaso)
            .then((response) => {
               this.result = response.data
               this.numeroCaso = this.result.numeroCaso
               this.carnet = this.result.carnet
               this.nombres = this.result.nombres
               this.apellidos = this.result.apellidos
               this.selectCarreraId = this.result.carreraUniversitariaId
               this.fechaSolicitud = this.parseDate(this.result.fechaSolicitud)
               this.email = this.result.email
               this.telefono = this.result.telefono
               this.selectAsesor = this.result.asesorId
               this.rutaArchivoAdjunto = this.result.rutaArchivoAdjunto
               this.comentarioAsesor = this.result.comentarioAsesor
               this.comentarioAlumno = this.result.comentarioAlumno
               this.selectEstadoCaso = this.result.estadoCasoId
               this.selectTipoCaso = this.result.tipoCasoId
               this.nTalonario = this.result.numeroTalonario
            })
            .catch(() => {
               //console.log(error)
            })
            .finally(() => {
               // Solicitando la generacion de detalles de plan de pago
               axios
                  .get(
                     'api/FormularioPlanPago/' +
                        item.numeroCaso +
                        '/DetallePlanPago'
                  )
                  .then((response) => {
                     var res = response.data
                     for (let i = 0; i < res.length; i++) {
                        const el = res[i]
                        this.planPagoMoras.push(el)
                        // console.log(el)
                        if (
                           el.cuotaNumero == 1 &&
                           el.periodoLectivo == this.cicloActual
                        ) {
                           break
                        }
                     }
                  })
                  .catch(() => {
                     //console.log(error)
                  })
                  .finally(() => {
                     if (this.planPagoMoras.length == 0) {
                        axios
                           .post(
                              'api/FormularioPlanPago/' +
                                 item.numeroCaso +
                                 '/DetallePlanPago',
                              []
                           )
                           .then((response) => {
                              var res = response.data
                              for (let i = 0; i < res.length; i++) {
                                 const el = res[i]
                                 this.planPagoMoras.push(el)
                                 console.log(el)
                                 if (
                                    el.cuotaNumero == 1 &&
                                    el.periodoLectivo == this.cicloActual
                                 ) {
                                    break
                                 }
                              }
                           })
                           .catch(() => {
                              //console.log(error)
                           })
                           .finally(() => {
                              this.tempMontoMatricula =
                                 this.planPagoMoras[
                                    this.planPagoMoras.length - 1
                                 ].monto
                              this.tempAsesorName = this.cmbAsesores.find(
                                 (obj) => {
                                    return obj.id === this.result.asesorId
                                 }
                              ).userClaims[0].claimValue
                              this.verNuevo = true
                           })
                     } else {
                        this.tempMontoMatricula =
                           this.planPagoMoras[
                              this.planPagoMoras.length - 1
                           ].monto
                        this.tempAsesorName = this.cmbAsesores.find((obj) => {
                           return obj.id === this.result.asesorId
                        }).userClaims[0].claimValue
                        this.verNuevo = true
                     }
                  })
            })
      },
      // Cargando informacion del item a editar
      editItem(item) {
         // Obteniendo valores para bodega segun id
         axios
            .get('api/FormularioPlanPago/' + item.numeroCaso)
            .then((response) => {
               this.result = response.data
               this.numeroCaso = this.result.numeroCaso
               this.carnet = this.result.carnet
               this.nombres = this.result.nombres
               this.apellidos = this.result.apellidos
               this.selectCarreraId = this.result.carreraUniversitariaId
               this.fechaSolicitud = this.parseDate(this.result.fechaSolicitud)
               this.email = this.result.email
               this.telefono = this.result.telefono
               this.selectAsesor = this.result.asesorId
               this.rutaArchivoAdjunto = this.result.rutaArchivoAdjunto
               this.comentarioAsesor = this.result.comentarioAsesor
               this.comentarioAlumno = this.result.comentarioAlumno
               this.selectEstadoCaso = this.result.estadoCasoId
               this.selectTipoCaso = this.result.tipoCasoId
               this.nTalonario = this.result.numeroTalonario
            })
            .catch(() => {
               //console.log(error)
            })
         this.editedIndex = 1
         this.dialogNotas = true
      },
      // Muestra el dialog para remover unabodega
      deleteItem(item) {
         //console.log(item)
         this.dialogEliminar = true
         this.carnet = item.carnet
         this.numeroCaso = item.numeroCaso
         this.nTalonario = item.numeroTalonario
      },
      // Cerrar ventana de dialogo
      close() {
         this.verNuevo = false
         this.dialogNotas = false
         this.dialogEliminar = false
         this.clear()
         setTimeout(() => {
            this.editedIndex = -1
         }, 300)
      },
      // Guardar/Actulizar bodega
      save() {
         if (this.$refs.formPlan.validate()) {
            if (this.editedIndex > -1) {
               // Codigo para actualizar/editar
               axios
                  .put('api/FormularioPlanPago/' + this.numeroCaso, {
                     nombres: this.nombres,
                     carnet: this.carnet,
                     apellidos: this.apellidos,
                     carreraUniversitariaId: this.selectCarreraId,
                     fechaSolicitud: this.fechaSolicitud,
                     email: this.email,
                     telefono: this.telefono,
                     asesorId: this.selectAsesor,
                     rutaArchivoAdjunto: this.rutaArchivoAdjunto,
                     comentarioAsesor: this.comentarioAsesor,
                     comentarioAlumno: this.comentarioAlumno,
                     estadoCasoId: this.selectEstadoCaso,
                     tipoCasoId: this.selectTipoCaso,
                     numeroTalonario: this.nTalonario
                  })
                  .then(() => {
                     this.listarSolicitudes()
                     this.close()
                     this.snackText = 'Solicitud editada exitosamente!'
                     this.snackColor = 'snackSuccess'
                     this.snackbar = true
                  })
                  .catch((error) => {
                     //console.log(error)
                     if (
                        error.response.status == 304 ||
                        error.response.status == 400
                     ) {
                        this.snackText = 'Solicitud sin cambios'
                        this.snackColor = 'snackInfo'
                        this.snackbar = true
                        this.listarSolicitudes()
                        this.close()
                     }
                  })
            } else {
               // Codigo para guardar
               axios
                  .post('api/FormularioPlanPago', {
                     nombres: this.nombres,
                     carnet: this.carnet,
                     apellidos: this.apellidos,
                     carreraUniversitariaId: this.selectCarreraId,
                     fechaSolicitud: this.fechaSolicitud,
                     email: this.email,
                     telefono: this.telefono,
                     asesorId: this.selectAsesor,
                     rutaArchivoAdjunto: this.rutaArchivoAdjunto,
                     comentarioAsesor: this.comentarioAsesor,
                     comentarioAlumno: this.comentarioAlumno,
                     estadoCasoId: this.selectEstadoCaso,
                     tipoCasoId: this.selectTipoCaso,
                     numeroTalonario: this.nTalonario
                  })
                  .then(() => {
                     this.listarSolicitudes()
                     this.close()
                     this.snackText = 'Solicitud agregado exitosamente!'
                     this.snackColor = 'snackSuccess'
                     this.snackbar = true
                  })
                  .catch(() => {
                     //console.log(error)
                  })
            }
         }
      },
      // Limpiar formularios y componentes
      clear() {
         try {
            this.$refs.formPlan.resetValidation()
         } catch (error) {
            //Do nothing
         }
         this.result = ''
         this.numeroCaso = ''
         this.carnet = ''
         this.nombres = ''
         this.apellidos = ''
         this.selectCarreraId = ''
         this.fechaSolicitud = this.toISOLocal(new Date()).substr(0, 10)
         this.email = ''
         this.telefono = ''
         this.selectAsesor = ''
         this.rutaArchivoAdjunto = ''
         this.comentarioAsesor = ''
         this.comentarioAlumno = ''
         this.selectEstadoCaso = ''
         this.selectTipoCaso = ''
         this.nTalonario = ''
         this.planPagoMoras = []
      },
      removerFormulario() {
         // Enviando metodo delete
         axios
            .delete('api/FormularioPlanPago/' + this.numeroCaso)
            .then(() => {
               this.listarSolicitudes()
               this.close()
               this.snackText = 'Formulario removida exitosamente!'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
            })
            .catch(() => {
               this.snackText = 'No se pudo remover el formulario.'
               this.snackColor = 'snackError'
               this.snackbar = true
            })
      },
      getColorEstadoSoli(item) {
         switch (item.estadoCasoId) {
            case 1:
               return 'blue'
               break
            case 2:
               return 'amber darken-2'
               break
            case 3:
               return 'green'
               break
            case 4:
               return 'red darken-3'
               break
            case 5:
               return 'grey darken-3'
               break
            case 6:
               return 'greylight-green'
               break
            case 7:
               return 'grey'
               break
            default:
               return 'grey darken-1'
               break
         }
      },
      getColorProgreso(item) {
         switch (item.progreso) {
            case 'En tiempo':
               return 'amber darken-2'
               break
            case 'Completado':
               return 'green'
               break
            case 'Con atraso':
               return 'red darken-3'
               break
            case 'Anulado':
               return 'grey darken-3'
               break
            case 'Denegado':
               return 'red darken-3'
               break
            case 'Retirado':
               return 'grey'
               break
            default:
               return 'grey darken-1'
               break
         }
      },
      getStatus(item) {
         if (
            this.toISOLocal(new Date()).substr(0, 10) >
            this.parseDate(item.fechaPago)
         ) {
            return 'En mora'
         } else {
            return 'Pendiente'
         }
      },
      getColorEstado(item) {
         if (
            this.toISOLocal(new Date()).substr(0, 10) >
               this.parseDate(item.fechaPago) &&
            !item.facturado
         ) {
            return 'red'
         } else {
            if (item.facturado) {
               return 'green'
            } else {
               return 'blue'
            }
         }
      },
      reportePagosDetallado() {
         var resPago = []

         axios
            .get(
               'api/FormularioPlanPago/ReporteDetallado?soloAprobados=' +
                  this.aprobados +
                  '&cicloAplicacion=' +
                  this.selectCicloPlanesPago
            )
            .then((response) => {
               resPago = response.data
            })
            .catch((error) => {
               console.log(error)
            })
            .finally(() => {
               // Generar excel
               var heading = [
                  [
                     'formularioPlanPagoId',
                     'totalPlan',
                     'cicloCuota',
                     'numeroCuota',
                     'montoCuota',
                     'estadoCuota',
                     'fechaDePago',
                     'carnet',
                     'numeroTalonario',
                     'nombres',
                     'apellidos',
                     'carreraUniversitaria',
                     'fechaSolicitud',
                     'email',
                     'telefono',
                     'asesor',
                     'rutaArchivoAdjunto',
                     'resolucion',
                     'tipoCaso',
                     'estado',
                     'cicloAplicacion'
                  ]
               ]
               var today = new Date()
               //today = today.substring(0, 16)
               var filename =
                  'Planes detallados con su monto al ' + today + '.xlsx'
               var wb = XLSX.utils.book_new()

               // Agregando headers y data de arreglo
               var ws = XLSX.utils.aoa_to_sheet(heading)
               XLSX.utils.sheet_add_json(ws, resPago, {
                  skipHeader: true,
                  origin: 'A2'
               })

               // Asignando ancho de columnas segun contenido
               this.autofitColumns(resPago, ws)

               // Agregando hoja y exportando archivo
               XLSX.utils.book_append_sheet(wb, ws, 'Reporte')
               XLSX.writeFile(wb, filename)
            })
      },
      reportePagosPlanPago() {
         var resPago = []

         axios
            .get(
               'api/FormularioPlanPago/ReporteTotalPlanPagos?soloAprobados=' +
                  this.aprobados +
                  '&cicloAplicacion=' +
                  this.selectCicloPlanesPago
            )
            .then((response) => {
               resPago = response.data
            })
            .catch((error) => {
               console.log(error)
            })
            .finally(() => {
               // Generar excel
               var heading = [
                  [
                     'formularioPlanPagoId',
                     'totalPlan',
                     'carnet',
                     'numeroTalonario',
                     'nombres',
                     'apellidos',
                     'carreraUniversitaria',
                     'fechaSolicitud',
                     'email',
                     'telefono',
                     'asesor',
                     'rutaArchivoAdjunto',
                     'resolucion',
                     'tipoCaso',
                     'estado',
                     'cicloAplicacion'
                  ]
               ]
               var today = new Date()
               //today = today.substring(0, 16)
               var filename = 'Totales por plan de pago al ' + today + '.xlsx'
               var wb = XLSX.utils.book_new()

               // Agregando headers y data de arreglo
               var ws = XLSX.utils.aoa_to_sheet(heading)
               XLSX.utils.sheet_add_json(ws, resPago, {
                  skipHeader: true,
                  origin: 'A2'
               })

               // Asignando ancho de columnas segun contenido
               this.autofitColumns(resPago, ws)

               // Agregando hoja y exportando archivo
               XLSX.utils.book_append_sheet(wb, ws, 'Reporte')
               XLSX.writeFile(wb, filename)
            })
      },

      actualizarDetalles() {
         this.dialogActualizarLoading = true
         axios
            .put('/api/FormularioPlanPago/HotFixPlanesDePago', {
               formularioPlanPagoId: this.numeroCaso,
               cicloActual: this.selectCicloPlanesPago,
               carnet: this.carnet
            })
            .then(() => {
               this.snackText = 'Detalles actualizados exitosamente'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
               this.getDetalles()
            })
            .catch((error) => {
               console.log(error)
               this.snackText = 'Detalles no actualizados'
               this.snackColor = 'snackError'
               this.snackbar = true
               this.dialogActualizarLoading = false
            })
            .finally(() => {
               this.dialogActualizar = false
               this.dialogActualizarLoading = false
            })
      },

      actualizarPlanPago() {
         axios
            .put('/api/FormularioPlanPago/ActualizarPlanesDePago', {
               periodoLectivo: this.selectCicloPlanesPago
            })
            .then(() => {
               this.snackText = 'Detalles actualizados exitosamente'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
               this.listarSolicitudes()
            })
            .catch((error) => {
               console.log(error)
               this.snackText = 'Detalles no actualizados'
               this.snackColor = 'snackError'
               this.snackbar = true
            })
      },

      actualizarPlanPagoIndividual() {
         axios
            .put('/api/FormularioPlanPago/ActualizarPlanDePagoIndividual', {
               periodoLectivo: this.selectCicloPlanesPago,
               carnet: this.carnet
            })
            .then(() => {
               this.snackText = 'Detalles actualizados exitosamente'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
               this.getDetalles()
            })
            .catch((error) => {
               console.log(error)

               this.snackText = 'Detalles no actualizados'
               this.snackColor = 'snackError'
               this.snackbar = true
            })
      },

      getDetalles() {
         axios
            .get(
               'api/FormularioPlanPago/' + this.numeroCaso + '/DetallePlanPago'
            )
            .then((response) => {
               this.planPagoMoras = response.data
            })
            .catch((error) => {
               console.log(error)
            })
            .finally(() => {
               this.closePlanCuota()
            })
      }
   }
}
</script>
